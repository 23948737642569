<template>
  <div>
    <section id="courses-header">
      <v-row class="mb-5">
        <v-col cols="12" sm="8" class="px-0">
          <h1>Manage courses details</h1>
          <h3 class="gray--text">Change, add or remove courses details</h3>
        </v-col>
        <v-col cols="12" sm="4" class="px-0">
          <v-btn depressed block large color="secondary" @click="toggleCourseDialog()"><v-icon class="pr-5">mdi-plus</v-icon>Add course</v-btn>
        </v-col>
      </v-row>
    </section>

    <section id="courses-table">
      <v-row v-if="$vuetify.breakpoint.smAndUp" class="gray--text">
        <v-col sm="2"><h1>Name</h1></v-col>
        <v-col sm="5"><h1>Description</h1></v-col>
        <v-col sm="5" class="text-center"><h1>Actions</h1></v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card v-for="course in courses" :key="course.id" class="my-5">
        <v-row class="d-flex align-center">
          <v-col cols="12" sm="2"><h3>{{course.name}}</h3></v-col>
          <v-col cols="12" sm="5"><h3>{{course.description | truncate(30)}}</h3></v-col>
          <v-col cols="12" sm="5" class="pa-0">
            <v-row>
              <v-col cols="6"><v-btn small block outlined color="success" @click="toggleCourseDialog(course)">Edit</v-btn></v-col>
              <v-col cols="6"><v-btn small block outlined color="danger" @click="toggleDeleteCourseDialog(course)">Remove</v-btn></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-row v-if="courses.length == 0" >
        <div class="mt-15 mb-5 mx-auto">
          <h1>No courses to show yet!</h1>
        </div>
      </v-row>
      <div class="text-center mt-10">
        <v-pagination
          v-model="pagination.current_page"
          :length="pagination.total_page"
          :total-visible="7"
          @input="fetchCourses(pagination.current_page)">
        </v-pagination>
      </div>
    </section>

    <section id="courses-dialog">
      <v-dialog v-model="courseDialog" max-width="700" eager>
        <v-card class="pa-5">
          <div class="d-flex align-center">
            <h3>{{ course.id ? 'Edit course details' : 'Add new course' }}</h3>
            <v-spacer></v-spacer>
            <v-btn icon @click="toggleCourseDialog()"><v-icon>mdi-close</v-icon></v-btn>
          </div>
          <v-form ref="CourseForm" v-model="validCourse">
            <v-row>
              <v-col cols="12" class="px-0 pb-0">
                <v-text-field outlined v-model="course.name" :rules="nameRules" required>
                  <template v-slot:label>Name <span class="red--text"> *</span></template>
                </v-text-field>
                <v-textarea outlined v-model="course.description" :rules="descriptionRules" auto-grow required>
                  <template v-slot:label>Description <span class="red--text"> *</span></template>
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="pt-0" :class="{ 'px-0': $vuetify.breakpoint.xsOnly, 'pl-0': $vuetify.breakpoint.smAndUp }">
                <small>Featured image</small>
                <v-img :src="course.featured_image_url || require('@/assets/default-landscape.png')" class="mx-auto course-image mt-1" :aspect-ratio="16/9" width="auto">
                  <template v-slot:placeholder>
                    <v-container fill-height class="d-flex align-center justify-center">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-container>
                  </template>
                </v-img>
                <input ref="FeaturedFileInput" type="file" style="display: none;" @change="onFileSelect"/>
                <v-btn block outlined class="mt-3" color="success" @click="$refs.FeaturedFileInput.click(), (cropperTarget = 'featured')">{{ course.id ? 'Change' : 'Upload' }}</v-btn>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0" :class="{ 'px-0': $vuetify.breakpoint.xsOnly, 'pr-0': $vuetify.breakpoint.smAndUp }">
                <small>Diagram image</small>
                <v-img :src="course.diagram_url || require('@/assets/default-landscape.png')" class="mx-auto course-image mt-1" :aspect-ratio="16/9" width="auto">
                  <template v-slot:placeholder>
                    <v-container fill-height class="d-flex align-center justify-center">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-container>
                  </template>
                </v-img>
                <input ref="DiagramFileInput" type="file" style="display: none;" @change="onFileSelect"/>
                <v-btn block outlined class="mt-3" color="success" @click="$refs.DiagramFileInput.click(), (cropperTarget = 'diagram')">{{ course.id ? 'Change' : 'Upload' }}</v-btn>
              </v-col>
              <v-dialog v-model="cropperDialog" width="500">
                <v-card class="pa-3">
                  <VueCropper v-show="selectedFile" :aspectRatio="16/9" ref="CropperRef" :src="selectedFile" alt="Source Image"></VueCropper>
                  <v-card-actions class="px-0">
                    <v-spacer></v-spacer>
                    <v-btn color="danger" text @click="cropperDialog = false">Cancel</v-btn>
                    <v-btn depressed class="primary" @click="cropImage(), (cropperDialog = false)">Crop</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-form>
          <ErrorMessage :errors="courseErrors"/>
          <v-card-actions class="pa-0 mt-5">
            <v-spacer></v-spacer>
            <v-btn depressed color="danger" class="white--text px-10" @click="toggleCourseDialog()">Cancel</v-btn>
            <v-btn depressed color="success" class="px-10"
              :loading="saveLoading" :disabled="saveLoading || !validCourse" @click="saveCourse()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteCourseDialog" max-width="500px">
        <v-card>
          <v-card-title class="black--text">
            <span><v-icon color="black">mdi-alert-circle-outline</v-icon> Remove <span class="primary--text">{{course.name}}</span> from courses list?</span>
          </v-card-title>
          <v-card-actions class="pb-5 pr-8">
            <v-spacer></v-spacer>
            <v-btn color="danger" text @click="toggleDeleteCourseDialog()">Cancel</v-btn>
            <v-btn depressed color="success" class="px-10"
              :loading="deleteLoading" :disabled="deleteLoading" @click="deleteCourse(course.id)">
              Yes, remove
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </section>
  </div>
</template>

<script>
  import VueCropper from 'vue-cropperjs'
  import 'cropperjs/dist/cropper.css'
  import apiService from '@/services/api.service'
  import notificationService from '@/services/notification.service'
  import ErrorMessage from '@/components/ErrorMessage'

  export default {
    name: 'AdminCoursesDetails',
    components: { VueCropper, ErrorMessage },
    data() {
      return {
        // Dialogs
        cropperDialog: false,
        courseDialog: false,
        deleteCourseDialog: false,

        // Form
        validCourse: false,
        saveLoading: false,
        deleteLoading: false,
        courses: [],
        course: {},
        nameRules: [name => !!name || 'Please enter a course name'],
        descriptionRules: [description => !!description || 'Please enter some description'],
        courseErrors: [],
        selectedFile: '',
        cropperTarget: '',
        pagination: {
          current_page: 0,
          per: 0,
          total_count: 0,
          total_page: 0
        }
      }
    },
    filters: {
      truncate(content, size) {
        if (!content) return ''
        content = content.toString()

        if (content.length <= size) return content
        return content.substr(0, size) + '...'
      }
    },
    created() {
      this.fetchCourses()
    },
    methods: {
      // Toggle dialog methods
      toggleCourseDialog(course = null) {
        this.courseErrors = []
        this.resetForm()
        this.courseDialog = !this.courseDialog
        if (course) this.getCourse(course.id)
      },
      toggleDeleteCourseDialog(course = null) {
        this.deleteCourseDialog = !this.deleteCourseDialog
        if (course) this.course = course
        else this.course = {}
      },

      // Cropper methods
      cropImage() {
        if (this.cropperTarget === 'featured') {
          this.course.featured_image_url = this.$refs.CropperRef.getCroppedCanvas().toDataURL()
          this.$refs.CropperRef.getCroppedCanvas().toBlob(blob => {
            this.course.featured_image = blob
          })
        }
        if (this.cropperTarget === 'diagram') {
          this.course.diagram_url = this.$refs.CropperRef.getCroppedCanvas().toDataURL()
          this.$refs.CropperRef.getCroppedCanvas().toBlob(blob => {
            this.course.diagram = blob
          })
        }
      },
      onFileSelect(e) {
        const file = e.target.files[0]
        if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
          notificationService.error('The picture type must be one of jpeg, jpg or png')
          return false
        }
        if (typeof FileReader !== 'function') {
          notificationService.error('Sorry, FileReader API not supported. Please try a different browser')
          return false
        }

        this.cropperDialog = true
        const reader = new FileReader()
        reader.onload = event => {
          this.selectedFile = event.target.result
          this.$refs.CropperRef.replace(this.selectedFile)
        }
        reader.readAsDataURL(file)
      },

      // API methods
      async fetchCourses(page = null) {
        await apiService.get(`api/v1/courses?page=${page}`).then(res => {
          if (res.status == 200) {
            this.courses = res.data.data
            if (!page) this.pagination = res.data.pagination
          }
        }).catch(() => notificationService.error('Something went wrong. Please refresh.'))
      },
      async getCourse(courseId) {
        await apiService.get(`api/v1/courses/${courseId}`).then(res => {
          if (res.status == 200) this.course = res.data.data
        }).catch(() => notificationService.error('Something went wrong. Please refresh.'))
      },
      saveCourse() {
        this.saveLoading = true
        this.courseErrors = []
        let formData = new FormData()
        if (this.course.name) formData.append('name', this.course.name)
        if (this.course.description) formData.append('description', this.course.description)
        if (this.course.featured_image) formData.append('featured_image', this.course.featured_image)
        if (this.course.diagram) formData.append('diagram', this.course.diagram)

        if (this.course.id) this.updateCourse(formData, this.course.id)
        else this.createCourse(formData)
      },
      async createCourse(formData) {
        await apiService.create('api/v1/courses', formData).then(res => {
          if (res.status == 200) this.cleanUpSuccess(res.data.message)
        }).catch(err => this.cleanUpFailure(err))
      },
      async updateCourse(formData, courseId) {
        await apiService.update(`api/v1/courses/${courseId}`, formData).then(res => {
          if (res.status == 200) this.cleanUpSuccess(res.data.message)
        }).catch(err => this.cleanUpFailure(err))
      },
      async deleteCourse(courseId) {
        this.deleteLoading = true
        await apiService.delete(`api/v1/courses/${courseId}`).then(res => {
          if (res.status == 200) this.cleanUpSuccess(res.data.message)
        }).catch(err => this.cleanUpFailure(err))
      },

      // Clean up methods
      resetForm() {
        this.$refs.CourseForm.reset()
        this.course = {}
      },
      cleanUpSuccess(content) {
        notificationService.success(content)
        this.fetchCourses()
        this.resetLoaders()
        this.selectedFile = null
        this.courseDialog = false
        this.deleteCourseDialog = false
      },
      cleanUpFailure(err) {
        this.resetLoaders()
        this.courseErrors = err.response.data.errors
        notificationService.error(err.response.data.message || 'Something went wrong. Please refresh.')
      },
      resetLoaders() {
        this.saveLoading = false
        this.deleteLoading = false
      }
    }
  }
</script>

<style lang="scss">
  @import '@/scss/style.scss';

  #courses-header {
    h1 {
      font-size: 2em;

      @media #{map-get($display-breakpoints, 'xs-only')} {
        font-size: 1.5em;
      }
    }
  }

  .course-image {
    border: 1px solid $black;
    border-radius: 4px;

    .course-ratio-box {
      width: 100%;
      padding-bottom: 56.25%;
    }
  }
</style>
