<template>
  <v-snackbar v-model="show" :color="color" top>
    <h3>{{message}}</h3>
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="show = false" color="white">Close</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  export default {
    name: 'Snackbar',
    data () {
      return {
        show: false,
        message: '',
        color: ''
      }
    },
    created () {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'showNotification') {
          this.message = state.snackbar.content
          this.color = state.snackbar.color
          this.show = true
        }
      })
    }
  }
</script>
