<template>
  <v-footer id="footer" padless>
    <v-container fluid class="pa-0">
      <v-row class="bg-primary white--text pt-8 pb-13">
        <v-col v-if="$vuetify.breakpoint.smAndUp" cols="12" sm="6" md="3" class="d-flex align-center justify-center">
          <v-img contain :src="require('@/assets/logo.png')" transition="scale-transition" max-width="175"/>
        </v-col>
        <v-col cols="12" sm="6" md="3" class="px-8">
          <h1>Connect With Us</h1>
          <v-divider class="mb-5"></v-divider>
          <p>KM 15 ½, Spg 413, Jln Kelab Golf Pantai, Kg Pangkalan Sibabau, BU2129, Mukim Mentiri, Brunei Darussalam</p>
          <router-link to="/">
            <v-btn block outlined color="white">More about us <v-icon right>mdi-chevron-right</v-icon></v-btn>
          </router-link>
        </v-col>
        <v-col cols="12" sm="6" md="3" class="px-8">
          <h1>Site Maps</h1>
          <v-divider class="mb-3"></v-divider>
          <router-link v-for="item in siteMapItems" :key="item.id" :to="item.path">
            <v-btn text plain color="white" :ripple="false" class="pl-0">{{item.name}}</v-btn>
          </router-link>
        </v-col>
        <v-col cols="12" sm="6" md="3" class="px-8">
          <h1>Follow Us</h1>
          <v-divider class="mb-4"></v-divider>
          <v-btn large icon color="white" href="https://www.facebook.com/pages/Pantai%20Mentiri%20Golf%20Club/154158147980961/" target="_blank"><v-icon large>mdi-facebook</v-icon></v-btn>
          <v-btn large icon color="white" href="https://www.instagram.com/pantaimentiri_golfclub/?hl=en" target="_blank" class="ml-2"><v-icon large>mdi-instagram</v-icon></v-btn>
        </v-col>
      </v-row>
      <div class="text-center bg-secondary white--text">
        <p class="py-4 mb-0">
          <v-icon small color="white" class="pr-2 pb-1">mdi-copyright</v-icon>
          {{ new Date().getFullYear() }} Pantai Mentiri Golf Club. All rights reserved <span v-if="$vuetify.breakpoint.smAndUp" class="px-5">|</span>
          <br v-if="$vuetify.breakpoint.xsOnly"> Powered by <a href="https://synapsebn.com" target="_blank" class="white-link">SynapseBN</a>
        </p>
      </div>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'Footer',
    data () {
      return {
        siteMapItems: [
          { id: 1, name: 'Home', path: '/' },
          { id: 2, name: 'Committee members', path: '/about/committee-members' },
          { id: 3, name: 'Reciprocal clubs', path: '/about/reciprocal-clubs' },
          { id: 4, name: 'Facilities', path: '/facilities' },
          { id: 5, name: 'Hole details', path: '/courses/hole-details' },
          { id: 6, name: 'Courses rates', path: '/courses/rates' },
          { id: 7, name: 'News', path: '/news' },
          { id: 8, name: 'Tournament', path: '/tournament' },
          { id: 9, name: 'Contact us', path: '/contact-us' }
        ]
      }
    }
  }
</script>

<style lang="scss">
  #footer {
    .v-footer {
      display: block !important;
    }

    .theme--light.v-divider {
      border-color: #ffffff !important;
      max-width: 25%;
    }

    .white-link {
      text-decoration: none;
      color: white !important;
    }
  }
</style>
