import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    adminCredentials: {},
    snackbar: {}
  },
  mutations: {
    setAdminCredentials(state, payload) {
      state.adminCredentials = payload
      localStorage.setItem('adminCredentials', JSON.stringify(payload))
    },
    showNotification (state, val) {
      state.snackbar.content = val.content
      state.snackbar.color = val.color
    }
  }
})
