<template>
  <section id="reciprocal-clubs" class="my-10">
    <v-tabs v-model="tab" background-color="primary" :fixed-tabs="$vuetify.breakpoint.mdAndUp" center-active dark show-arrows class="rounded-lg" :class="{ 'px-10': $vuetify.breakpoint.smAndUp, 'px-5': $vuetify.breakpoint.xsOnly }">
      <v-tab v-for="(country, index) in countries" :key="index" @click="fetchReciprocalClubs(country)">{{country}}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="mt-5" :class="{ 'px-10': $vuetify.breakpoint.smAndUp, 'px-5': $vuetify.breakpoint.xsOnly }">
      <v-tab-item v-for="(country, index) in countries" :key="index">
        <v-row>
          <v-col v-for="club in clubs" :key="club.id" cols="12" sm="6" md="4">
            <v-card class="pa-3">
              <h2 class="mb-4">{{club.name}}</h2>
              <h4 class="my-2"><v-icon class="pr-2 pb-1">mdi-map-marker</v-icon>{{club.location}}</h4>
              <h4 class="my-2"><v-icon class="pr-2">mdi-phone</v-icon>{{club.telephone}}</h4>
              <h4 class="my-2"><v-icon class="pr-2">mdi-fax</v-icon>{{club.fax}}</h4>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="clubs.length == 0" >
          <div class="mt-15 mb-5 mx-auto">
            <h1>No clubs to show yet!</h1>
          </div>
        </v-row>

        <div class="text-center mt-10">
          <v-pagination
            v-model="pagination.current_page"
            :length="pagination.total_page"
            :total-visible="7"
            @input="fetchReciprocalClubs(country, pagination.current_page)">
          </v-pagination>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </section>
</template>

<script>
  import apiService from '@/services/api.service'

  export default {
    name: 'ReciprocalClubs',
    data() {
      return {
        tab: null,
        countries: ['Australia', 'Brunei', 'Indonesia', 'Malaysia', 'Philippines', 'Singapore'],
        clubs: [],
        pagination: {
          current_page: 0,
          per: 0,
          total_count: 0,
          total_page: 0
        }
      }
    },
    created() {
      this.fetchReciprocalClubs('Australia')
    },
    methods: {
      async fetchReciprocalClubs(country, page) {
        await apiService.get(`api/v1/reciprocal_clubs?country=${country}&page=${page}&per=12`).then(res => {
          if (res.status == 200) {
            this.clubs = res.data.data
            if (!page) this.pagination = res.data.pagination
          }
        }).catch(() => {})
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '@/scss/style.scss';

  #reciprocal-clubs {
    .v-sheet.v-card {
      border-radius: 4px 4px 0 0;
      border-bottom: 3px solid $primary;
    }

    h1 {
      font-size: 2em;
      line-height: 40px;
    }
  }
</style>
