<template>
  <section id="committee-members" class="my-5">
    <v-row :class="{ 'px-15': $vuetify.breakpoint.mdAndUp, 'px-5': $vuetify.breakpoint.smAndDown }">
      <v-col v-for="member in committeeMembers" :key="member.id" cols="12" sm="6" class="pa-0">
        <v-row class="mb-5">
          <v-col cols="12" sm="6" md="5">
            <v-img :src="member.image_url || require('@/assets/default-passport.png')" class="rounded-sm" width="auto" :aspect-ratio="7/9">
              <template v-slot:placeholder>
                <v-container fill-height class="d-flex align-center justify-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-container>
              </template>
            </v-img>
          </v-col>
          <v-col cols="12" sm="6" md="7">
            <h1 class="pb-2">{{member.title}}</h1>
            <h2 class="gray--text">{{member.name}}</h2>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-if="committeeMembers.length == 0" >
      <div class="my-15 mx-auto empty-state">
        <h1>No committee members to show yet!</h1>
      </div>
    </v-row>
  </section>
</template>

<script>
  import apiService from '@/services/api.service'

  export default {
    name: 'CommitteeMembers',
    data() {
      return {
        committeeMembers: []
      }
    },
    created() {
      this.fetchCommitteeMembers()
    },
    methods: {
      async fetchCommitteeMembers() {
        await apiService.get('api/v1/committee_members?no_paginate=true').then(res => {
          if (res.status == 200) this.committeeMembers = res.data.data
        }).catch(() => {})
      }
    }
  }
</script>

<style lang="scss">
  #committee-members {
    h1 {
      font-size: 2em;
    }

    .empty-state {
      h1 {
        line-height: 40px;
      }
    }
  }
</style>
