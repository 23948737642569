import Vue from 'vue'
import VueRouter from 'vue-router'

// UI views
import Home from '@/views/Home.vue'
import Facilities from '@/views/Facilities.vue'
import CoursesDetails from '@/views/CoursesDetails.vue'
import CommitteeMembers from '@/views/CommitteeMembers.vue'
import ReciprocalClubs from '@/views/ReciprocalClubs.vue'
import Contact from '@/views/Contact.vue'
import PostsList from '@/views/PostsList.vue'
import PostDetails from '@/views/PostDetails.vue'
import CoursesRates from '@/views/CoursesRates.vue'
import TournamentRates from '@/views/TournamentRates.vue'

// Admin views
import AdminLogin from '@/views/admin/AdminLogin.vue'
import AdminDashboard from '@/views/admin/AdminDashboard.vue'
import AdminCommitteeMembers from '@/views/admin/AdminCommitteeMembers.vue'
import AdminReciprocalClubs from '@/views/admin/AdminReciprocalClubs.vue'
import AdminCoursesDetails from '@/views/admin/AdminCoursesDetails.vue'
import AdminFacilities from '@/views/admin/AdminFacilities.vue'
import AdminMemberships from '@/views/admin/AdminMemberships.vue'
import AdminBanners from '@/views/admin/AdminBanners.vue'
import AdminPosts from '@/views/admin/AdminPosts.vue'
import AdminPostDetails from '@/views/admin/AdminPostDetails.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/facilities',
    name: 'Facilities',
    component: Facilities
  },
  {
    path: '/courses/hole-details',
    name: 'CoursesDetails',
    component: CoursesDetails
  },
  {
    path: '/about/committee-members',
    name: 'CommitteeMembers',
    component: CommitteeMembers
  },
  {
    path: '/about/reciprocal-clubs',
    name: 'ReciprocalClubs',
    component: ReciprocalClubs
  },
  {
    path: '/contact-us',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/news',
    name: 'PostsList',
    component: PostsList
  },
  {
    path: '/news/:id',
    name: 'PostDetails',
    component: PostDetails
  },
  {
    path: '/courses/rates',
    name: 'CoursesRates',
    component: CoursesRates
  },
  {
    path: '/tournament',
    name: 'TournamentRates',
    component: TournamentRates
  },
  {
    path: '/admin',
    name: 'AdminLogin',
    component: AdminLogin
  },
  {
    path: '/admin/dashboard',
    name: 'AdminDashboard',
    component: AdminDashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/committee',
    name: 'AdminCommitteeMembers',
    component: AdminCommitteeMembers,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/reciprocal-clubs',
    name: 'AdminReciprocalClubs',
    component: AdminReciprocalClubs,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/courses',
    name: 'AdminCoursesDetails',
    component: AdminCoursesDetails,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/facilities',
    name: 'AdminFacilities',
    component: AdminFacilities,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/memberships',
    name: 'AdminMemberships',
    component: AdminMemberships,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/banners',
    name: 'AdminBanners',
    component: AdminBanners,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/news',
    name: 'AdminPosts',
    component: AdminPosts,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/news/new',
    name: 'AdminPostNew',
    component: AdminPostDetails,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/news/edit/:id',
    name: 'AdminPostEdit',
    component: AdminPostDetails,
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  basE: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

// Route guards
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(path => path.meta.requiresAuth)
  const isLoggedIn = localStorage.getItem('adminCredentials')

  if (requiresAuth && !isLoggedIn) next('/admin')
  else if (isLoggedIn && to.name == 'AdminLogin') next('/admin/banners')
  else next()
})

export default router
