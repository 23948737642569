<template>
  <div>
    <section id="banners-header">
      <v-row class="mb-5">
        <v-col cols="12" class="px-0">
          <h1>Manage home page banners</h1>
          <h3 class="gray--text">Change, add or remove banners</h3>
        </v-col>
      </v-row>
    </section>

    <section id="banners-list">
      <v-row>
        <v-col cols="12" sm="6" v-for="banner in banners" :key="banner.id" :class="{ 'px-0': $vuetify.breakpoint.xsOnly }">
          <small>Title: {{banner.title}}</small>
          <v-img :src="banner.image_url" class="mx-auto mt-1 banner-image" :aspect-ratio="16/9" width="auto">
            <template v-slot:placeholder>
              <v-container fill-height class="d-flex align-center justify-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-container>
            </template>
          </v-img>
          <input :ref="`BannerFileInput${banner.id}`" type="file" style="display: none;" @change="onFileSelect"/>
          <v-row>
            <v-col class="pl-0"><v-btn block outlined color="danger" class="white--text" @click="toggleDeleteBannerDialog(banner)">Remove</v-btn></v-col>
            <v-col class="pr-0"><v-btn block outlined color="success" @click="$refs[`BannerFileInput${banner.id}`][0].click(), (cropperTarget = banner.id)">Change</v-btn></v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" :order="$vuetify.breakpoint.xsOnly ? 'first' : 'last'" :class="{ 'px-0': $vuetify.breakpoint.xsOnly }">
          <input ref="NewBannerInput" type="file" style="display: none;" @change="onFileSelect"/>
          <a @click="$refs.NewBannerInput.click()">
            <small>New slide</small>
            <v-card flat v-ripple class="center-container primary--text banner-image banner-card mt-1">
              <div class="banner-ratio-box">
                <div class="center-all">
                  <h2><v-icon color="primary" class="pb-1">mdi-plus</v-icon>Add new slide</h2>
                </div>
              </div>
            </v-card>
          </a>
        </v-col>

        <v-dialog v-model="cropperDialog" width="500">
          <v-card class="pa-3">
            <VueCropper v-show="selectedFile" :aspectRatio="16/9" ref="CropperRef" :src="selectedFile" alt="Source Image"></VueCropper>
            <v-card-actions class="px-0">
              <v-spacer></v-spacer>
              <v-btn color="danger" text @click="cropperDialog = false">Cancel</v-btn>
              <v-btn depressed class="primary" @click="cropImage(), (cropperDialog = false)">Crop</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-dialog v-model="deleteBannerDialog" max-width="500px">
        <v-card>
          <v-card-title class="black--text">
            <span><v-icon color="black">mdi-alert-circle-outline</v-icon> Remove  <span class="primary--text">#{{banner.title}}</span> from banners list?</span>
          </v-card-title>
          <v-card-actions class="pb-5 pr-8">
            <v-spacer></v-spacer>
            <v-btn color="danger" text @click="toggleDeleteBannerDialog()">Cancel</v-btn>
            <v-btn depressed color="success" class="px-10"
              :loading="deleteLoading" :disabled="deleteLoading" @click="deleteBanner(banner.id)">
              Yes, remove
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </section>
  </div>
</template>

<script>
  import VueCropper from 'vue-cropperjs'
  import 'cropperjs/dist/cropper.css'
  import apiService from '@/services/api.service'
  import notificationService from '@/services/notification.service'

  export default {
    name: 'AdminBanners',
    components: { VueCropper },
    data() {
      return {
        cropperDialog: false,
        deleteBannerDialog: false,
        deleteLoading: false,
        banners: [],
        banner: { image: null },
        selectedFile: '',
        cropperTarget: null
      }
    },
    created() {
      this.fetchBanners()
    },
    methods: {
      // Toggle dialog methods
      toggleDeleteBannerDialog(banner = null) {
        this.deleteBannerDialog = !this.deleteBannerDialog
        if (banner) this.banner = banner
      },

      // Cropper methods
      cropImage() {
        if (this.cropperTarget) {
          const targetBanner = this.banners.find(banner => banner.id == this.cropperTarget)
          targetBanner.image_url = this.$refs.CropperRef.getCroppedCanvas().toDataURL()
          this.$refs.CropperRef.getCroppedCanvas().toBlob(blob => {
            let formData = new FormData()
            formData.append('title', `slide_${blob.size}`)
            formData.append('image', blob)
            this.updateBanner(formData, targetBanner.id)
          })
        } else {
          this.$refs.CropperRef.getCroppedCanvas().toBlob(blob => {
            let formData = new FormData()
            formData.append('title', `slide_${blob.size}`)
            formData.append('image', blob)
            this.saveBanner(formData)
          })
        }
      },
      onFileSelect(e) {
        const file = e.target.files[0]
        if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
          notificationService.error('The picture type must be one of jpeg, jpg or png')
          return false
        }
        if (typeof FileReader !== 'function') {
          notificationService.error('Sorry, FileReader API not supported. Please try a different browser')
          return false
        }

        this.cropperDialog = true
        const reader = new FileReader()
        reader.onload = event => {
          this.selectedFile = event.target.result
          this.$refs.CropperRef.replace(this.selectedFile)
        }
        reader.readAsDataURL(file)
      },

      // API methods
      async fetchBanners() {
        await apiService.get('api/v1/banners').then(res => {
          if (res.status == 200) this.banners = res.data.data
        }).catch(() => notificationService.error('Something went wrong. Please refresh.'))
      },
      async saveBanner(formData) {
        await apiService.create('api/v1/banners', formData).then(res => {
          if (res.status == 200) this.cleanUpSuccess(res.data.message)
        }).catch(err => notificationService.error(err.response.data.message || 'Something went wrong. Please refresh.'))
      },
      async updateBanner(formData, bannerId) {
        await apiService.update(`api/v1/banners/${bannerId}`, formData).then(res => {
          if (res.status == 200) this.cleanUpSuccess(res.data.message)
        }).catch(err => notificationService.error(err.response.data.message || 'Something went wrong. Please refresh.'))
      },
      async deleteBanner(bannerId) {
        this.deleteLoading = true
        await apiService.delete(`api/v1/banners/${bannerId}`).then(res => {
          if (res.status == 200) this.cleanUpSuccess(res.data.message)
        }).catch(err => notificationService.error(err.response.data.message || 'Something went wrong. Please refresh.'))
      },

      // Clean up methods
      cleanUpSuccess(content) {
        notificationService.success(content)
        this.fetchBanners()
        this.deleteLoading = false
        this.deleteBannerDialog = false
      }
    }
  }
</script>

<style lang="scss">
  @import '@/scss/style.scss';

  #banners-header {
    h1 {
      font-size: 2em;

      @media #{map-get($display-breakpoints, 'xs-only')} {
        font-size: 1.5em;
      }
    }
  }

  #banners-list {
    .banner-image {
      border: 1px solid $black;
      border-radius: 4px;

      .banner-ratio-box {
        width: 100%;
        padding-bottom: 56.25%;
      }
    }

    .banner-card.v-sheet.v-card {
      border: 2px dashed $primary;

      @media #{map-get($display-breakpoints, 'xs-only')} {
        h2 {
          font-size: 18px;
        }
      }
    }
  }
</style>
