<template>
  <section id="facilities-list" class="mt-5 pb-10">
    <v-row v-for="(facility, index) in facilities" :key="facility.id">
      <template v-if="$vuetify.breakpoint.smAndUp">
        <template v-if="index % 2 == 0">
          <v-col cols="12" sm="5" offset-sm="1">
            <v-img :src="facility.featured_image_url || require('@/assets/default-landscape.png')" :aspect-ratio="16/9" width="auto">
              <template v-slot:placeholder>
                <v-container fill-height class="d-flex align-center justify-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-container>
              </template>
            </v-img>
          </v-col>
          <v-col cols="12" sm="5" class="text-left d-flex align-center">
            <div>
              <h2 class="mt-2">{{facility.name}}</h2>
              <h3 class="gray--text">{{facility.description}}</h3>
            </div>
          </v-col>
        </template>
        <template v-if="index % 2 != 0">
          <v-col cols="12" sm="5" offset-sm="1" class="text-right d-flex align-center">
            <div>
              <h2 class="mt-2">{{facility.name}}</h2>
              <h3 class="gray--text">{{facility.description}}</h3>
            </div>
          </v-col>
          <v-col cols="12" sm="5">
            <v-img :src="facility.featured_image_url || require('@/assets/default-landscape.png')" :aspect-ratio="16/9" width="auto">
              <template v-slot:placeholder>
                <v-container fill-height class="d-flex align-center justify-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-container>
              </template>
            </v-img>
          </v-col>
        </template>
      </template>
      <template v-if="$vuetify.breakpoint.xsOnly">
        <v-col cols="12" sm="5" offset-sm="1" class="px-10">
          <v-img
            :src="facility.featured_image_url || require('@/assets/default-landscape.png')"
            class="facility-featured-image" :aspect-ratio="16/9" width="auto">
            <template v-slot:placeholder>
              <v-container fill-height class="d-flex align-center justify-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-container>
            </template>
          </v-img>
        </v-col>
        <v-col cols="12" sm="5" class="text-left px-10">
          <h2 class="mt-2">{{facility.name}}</h2>
          <h3 class="gray--text">{{facility.description}}</h3>
        </v-col>
      </template>
    </v-row>

    <v-row v-if="facilities.length == 0" >
      <div class="my-15 mx-auto">
        <h1>No facilities to show yet!</h1>
      </div>
    </v-row>
  </section>
</template>

<script>
  import apiService from '@/services/api.service'

  export default {
    name: 'Facilities',
    data () {
      return {
        facilities: []
      }
    },
    created() {
      this.fetchFacilities()
    },
    methods: {
      async fetchFacilities() {
        await apiService.get('api/v1/facilities?no_paginate=true').then(res => {
          if (res.status == 200) this.facilities = res.data.data
        }).catch(() => {})
      }
    }
  }
</script>

<style lang="scss">
  #facilities-list {
    h1 {
      font-size: 2em;
      line-height: 40px;
    }

    .facility-featured-image {
      border-radius: 4px;

      .facility-ratio-box {
        width: 100%;
        padding-bottom: 56.25%;
      }
    }
  }
</style>
