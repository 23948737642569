<template>
  <v-app id="side-drawer">
    <v-navigation-drawer app
      color="primary"
      class="white--text"
      :mini-variant.sync="mini"
      permanent>
      <v-list-item class="px-3 py-2 white">
        <v-img :src="require('@/assets/logo.png')" width="70"></v-img>
        <v-list-item-title class="ml-5 admin-title"><h1>Admin</h1></v-list-item-title>
      </v-list-item>

      <v-list>
        <v-list-group v-if="$vuetify.breakpoint.mdAndUp" class="drawer-content" prepend-icon="mdi-web">
          <template v-slot:activator>
            <v-list-item-content class="white--text">
              <h1>Website</h1>
            </v-list-item-content>
          </template>

          <v-list-item v-for="item in siteItems" :key="item.id" link :to="item.path">
            <v-list-item-icon>
              <v-icon color="white">{{item.icon}}</v-icon>
            </v-list-item-icon>
            <h3 class="white--text">{{item.name}}</h3>
          </v-list-item>
        </v-list-group>

        <v-menu v-if="$vuetify.breakpoint.smAndDown" bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="pl-5 pb-2 my-2">
              <v-icon color="white">mdi-web</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-for="item in siteItems" :key="item.id" link :to="item.path">
              <v-list-item-icon>
                <v-icon color="primary">{{item.icon}}</v-icon>
              </v-list-item-icon>
              <h3 class="primary--text">{{item.name}}</h3>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-divider color="white"></v-divider>

        <v-list-item link @click="goToHome()">
          <v-list-item-icon>
            <v-icon class="white--text">mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title><h3 class="white--text">Return home</h3></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="white" link @click="openLogoutDialog()">
          <v-list-item-icon>
            <v-icon class="red--text">mdi-import</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title><h3 class="red--text">Logout</h3></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <div class="main-view">
      <v-main class="pa-3">
        <v-container fluid>
          <router-view></router-view>
        </v-container>
      </v-main>
    </div>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="black--text">
            <span><v-icon color="black">mdi-alert-circle-outline</v-icon> Are you sure you want to logout?</span>
          </v-card-title>
        <v-card-actions class="pb-5 pr-8">
          <v-spacer></v-spacer>
          <v-btn color="danger" text @click="dialog = false">Cancel</v-btn>
          <v-btn depressed color="success" class="white--text" @click="logout()">Confirm logout</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
  export default {
    name: 'SideDrawer',
    data() {
      return {
        dialog: false,
        siteItems: [
          { id: 1, name: 'Banners', icon: 'mdi-image-area', path: '/admin/banners' },
          { id: 2, name: 'Memberships', icon: 'mdi-wallet-membership', path: '/admin/memberships' },
          { id: 3, name: 'Courses', icon: 'mdi-map', path: '/admin/courses' },
          { id: 4, name: 'Facilities', icon: 'mdi-domain', path: '/admin/facilities' },
          { id: 5, name: 'Committee', icon: 'mdi-account-multiple', path: '/admin/committee' },
          { id: 6, name: 'Reciprocal clubs', icon: 'mdi-golf', path: '/admin/reciprocal-clubs' },
          { id: 7, name: 'News', icon: 'mdi-newspaper-variant-multiple', path: '/admin/news' },
        ]
      }
    },
    computed: {
      mini: {
        get() {
          return this.$vuetify.breakpoint.smAndDown
        },
        set() {}
      }
    },
    methods: {
      goToHome() {
        this.$router.push('/')
      },
      openLogoutDialog() {
        this.dialog = true
      },
      logout() {
        localStorage.removeItem('authHeaders')
        localStorage.removeItem('adminCredentials')
        this.$router.push('/')
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '@/scss/style.scss';

  ::v-deep .drawer-content {
    .theme--light.v-icon {
      color: white !important;
    }

    .v-list-item--active {
      color: white !important;
    }
  }

  #side-drawer {
    .admin-title {
      h1 {
        font-size: 3em;
        text-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
      }
    }

    .main-view {
      padding-left: 265px;

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        padding-left: 65px;
      }
    }
  }
</style>
