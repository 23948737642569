<template>
  <section id="banner-carousel">
    <v-carousel hide-delimiters :show-arrows="false" cycle :height="$vuetify.breakpoint.mdAndUp ? '70vh' : 'auto'">
      <template v-if="banners.length">
        <v-carousel-item
          eager v-for="banner in banners"
          :key="banner.id" :src="banner.image_url || require('@/assets/default-landscape.png')"
          width="auto">
          <v-overlay absolute></v-overlay>
        </v-carousel-item>
      </template>
      <template v-else>
        <v-img :src="require('@/assets/banner1.jpg')" width="autp" :aspect-ratio="16/9">
          <template v-slot:placeholder>
            <v-container fill-height class="d-flex align-center justify-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-container>
          </template>
        </v-img>
        <v-overlay absolute z-index="0"></v-overlay>
      </template>
    </v-carousel>
    <v-row>
      <v-col cols="12" :sm="showCTA ? 6 : 0" :md="showCTA ? 8 : 0" :class="{ 'px-10': $vuetify.breakpoint.xsOnly, 'px-15': $vuetify.breakpoint.smAndUp }">
        <h1 class="black--text">{{header}}<br></h1>
        <h2 class="gray--text">{{subheader}}</h2>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.smAndUp && showCTA" cols="12" sm="6" md="4" justify="center" class="d-flex align-center">
        <div class="d-flex align-center">
          <router-link to="/contact-us">
            <v-btn large outlined color="white" class="v-btn__secondary secondary--text">Contact us</v-btn>
          </router-link>
          <router-link to="/facilities">
            <v-btn depressed large color="secondary" class="ml-5">Learn more</v-btn>
          </router-link>
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
  import apiService from '@/services/api.service'
  export default {
    name: 'Banner',
    data() {
      return {
        header: '',
        subheader: '',
        showCTA: false,
        banners: []
      }
    },
    watch:{
      $route (to) {
        this.setHeaders(to)
      }
    },
    created() {
      this.setHeaders(this.$route)
      this.fetchBanners()
    },
    methods: {
      setHeaders(path) {
        switch (path.name) {
          case 'Home':
            this.header = 'Welcome to'
            this.subheader = 'Pantai Mentiri Golf Club'
            this.showCTA = true
            break
          case 'Facilities':
            this.header = 'Club Facilities'
            this.subheader = 'PMGC is equipped with a number of facilities'
            this.showCTA = false
            break
          case 'CoursesDetails':
            this.header = 'The Courses'
            this.subheader = 'Enjoy the best 18 holes of golf in Brunei'
            this.showCTA = false
            break
          case 'CommitteeMembers':
            this.header = 'Executive Committee Members'
            this.subheader = 'Meet the committee'
            this.showCTA = false
            break
          case 'ReciprocalClubs':
            this.header = 'Reciprocal Clubs'
            this.subheader = 'We have reciprocal clubs all across Southeast Asia'
            this.showCTA = false
            break
          case 'Contact':
            this.header = 'Contact Us!'
            this.subheader = 'We are more than happy to assist you with any inquiries'
            this.showCTA = false
            break
          case 'PostsList':
            this.header = "What's new with PMGC?"
            this.subheader = 'Check out our latest news and tournaments here!'
            this.showCTA = false
            break
          case 'CoursesRates':
            this.header = 'Courses Rates'
            this.subheader = 'Figure out how much are you paying quickly and easily!'
            this.showCTA = false
            break
          case 'TournamentRates':
            this.header = 'Tournament Rates'
            this.subheader = 'Want to host a tournament at PMGC? Use our rates calculator to figure out your costs!'
            this.showCTA = false
            break
          case 'AdminLogin':
            this.header = 'Admin Login'
            this.subheader = 'Login as PMGC admin'
            this.showCTA = false
            break
        }
      },
      fetchBanners() {
        apiService.get('api/v1/banners').then(res => {
          if (res.status == 200) this.banners = res.data.data
        }).catch(() => {})
      }
    }
  }
</script>

<style lang="scss">
  @import '@/scss/style.scss';

  #banner-carousel {
    .v-overlay {
      display: inline !important;
    }

    .v-overlay__scrim {
      opacity: 1 !important;
      background: rgb(255, 255, 255) !important;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 1) 100%) !important;
    }

    h1 {
      font-size: 4em;
      line-height: 50px;
      text-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
    }

    h2 {
      padding-top: 25px;
      font-size: 3em;
      line-height: 50px;
      text-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
    }

    @media #{map-get($display-breakpoints, 'sm-only')} {
      h1 {
        font-size: 3em;
      }

      h2 {
        padding-top: 10px;
        font-size: 2.5em;
      }
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
      h1 {
        font-size: 2.5em;
      }

      h2 {
        padding-top: 10px;
        font-size: 2em;
        line-height: 40px;
      }
    }
  }
</style>
