import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0052A3',
        secondary: '#003D7A',
        success: '#66BB6A',
        danger: '#E44A59',
        gray: '#9A9A9A',
        black: '#333333'
      }
    }
  }
})
