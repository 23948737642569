<template>
  <v-card class="pa-5 my-5">
    <h2>{{title}}</h2>
    <v-divider class="my-3"></v-divider>
    <v-row>
      <v-col cols="2" sm="1" md="3" class="d-flex align-center justify-end py-0">
        <v-checkbox indeterminate hide-details color="secondary" disabled></v-checkbox>
      </v-col>
      <v-col cols="6" sm="6" md="4" class="d-flex align-center justify-end py-0 pl-0">
        <h2 class="mb-0 gray--text">Green Fee:</h2>
      </v-col>
      <v-col cols="4" sm="3" md="5" class="text-left d-flex align-center justify-start py-0">
        <h2 class="mb-0">{{pricing.greenPrice | toCurrency}}</h2>
      </v-col>
    </v-row>
    <v-row v-if="memberType !== 'members'">
      <v-col cols="2" sm="1" md="3" class="d-flex align-center justify-end py-0">
        <v-checkbox :indeterminate="pricing.buggy.isIncluded" :disabled="pricing.buggy.isIncluded" hide-details v-model="pricing.levy.isIncluded" color="secondary"></v-checkbox>
      </v-col>
      <v-col cols="6" sm="6" md="4" class="d-flex align-center justify-end py-0">
        <h2 class="mb-0 gray--text">Walking:</h2>
      </v-col>
      <v-col cols="4" sm="3" md="5" class="text-left d-flex align-center justify-start py-0">
        <h2 class="mb-0" :class=" pricing.levy.isIncluded ? '' : 'gray--text'">{{pricing.levy.price | toCurrency}}</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2" sm="1" md="3" class="d-flex align-center justify-end py-0">
        <v-checkbox :indeterminate="pricing.levy.isIncluded" :disabled="pricing.levy.isIncluded" hide-details v-model="pricing.buggy.isIncluded" color="secondary"></v-checkbox>
      </v-col>
      <v-col cols="6" sm="6" md="4" class="d-flex align-center justify-end py-0">
        <h2 class="mb-0 gray--text">Buggy:</h2>
      </v-col>
      <v-col cols="4" sm="3" md="5" class="text-left d-flex align-center justify-start py-0">
        <h2 class="mb-0" :class=" pricing.buggy.isIncluded ? '' : 'gray--text'">{{pricing.buggy.price | toCurrency}}</h2>
      </v-col>
    </v-row>
    <v-simple-table v-if="memberType == 'members'" class="mt-4">
      <thead>
        <tr>
          <th :colspan="$vuetify.breakpoint.xsOnly ? 3 : 4">
            <h2 class="mb-0 text-center gray--text">Check Your Walking Fee Here</h2>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            Monday - Thursday
            <template v-if="$vuetify.breakpoint.xsOnly">
              <br>
              <small>Before 3.30pm</small>
            </template>
          </td>
          <td v-if="$vuetify.breakpoint.smAndUp">Before 3.30pm</td>
          <td><b>$3.00</b></td>
        </tr>
        <tr>
          <td>
            Monday - Thursday
            <template v-if="$vuetify.breakpoint.xsOnly">
              <br>
              <small>After 3.30pm</small>
            </template>
          </td>
          <td v-if="$vuetify.breakpoint.smAndUp">After 3.30pm</td>
          <td><b>$1.00</b></td>
        </tr>
        <tr>
          <td colspan="4">Friday - Saturday (Not Available)</td>
        </tr>
        <tr>
          <td>
            Sunday
            <template v-if="$vuetify.breakpoint.xsOnly">
              <br>
              <small>After 3.30pm</small>
            </template>
          </td>
          <td v-if="$vuetify.breakpoint.smAndUp">After 3.30pm</td>
          <td><b>$1.00</b></td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-divider class="mt-3"></v-divider>
    <v-row>
      <v-col cols="8" sm="7" md="7" class="text-right">
        <h2>Total:</h2>
      </v-col>
      <v-col cols="4" sm="5" md="5" class="text-left">
        <h2 class="mb-0">{{calculatePrice(pricing.levy.isIncluded, pricing.levy.price, pricing.greenPrice, pricing.buggy.isIncluded, pricing.buggy.price) | toCurrency }}
          <small>/player</small>
        </h2>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  export default {
    name: 'PricingCard',
    props: ['title', 'pricing', 'memberType'],
    data() {
      return {
        pricing1: false,
        pricing2: false,
        pricing3: false
      }
    },
    filters: {
      toCurrency(value) {
        if (typeof value !== 'number') return value
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        })
        return formatter.format(value)
      }
    },
    methods: {
      calculatePrice(includeLevy, levyPrice, greenFee, includeBuggy, buggyPrice) {
        let levyFee = includeLevy ? levyPrice : 0
        let buggyFee = includeBuggy ? buggyPrice : 0
        return greenFee + levyFee + buggyFee
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '@/scss/style.scss';

  ::v-deep .v-input--selection-controls {
    margin-top: 0;

    .v-icon {
      color: $secondary;
    }
  }

  .theme--light.v-divider {
    border-color: $secondary !important;
    max-width: 100%;
  }

  small {
    font-size: 70%;
  }
</style>
