<template>
  <div>
    <section id="contact-us" class="mt-5" :class="{ 'px-10': $vuetify.breakpoint.smAndUp, 'px-5': $vuetify.breakpoint.xsOnly }">
      <v-row>
        <v-col cols="12" sm="6">
          <v-form ref="contactForm" v-model="valid">
            <v-text-field outlined label="Email" v-model="form.email" :rules="emailRules" class="error-message"></v-text-field>
            <v-text-field outlined label="Subject" v-model="form.subject" :rules="subjectRules" class="error-message"></v-text-field>
            <v-textarea outlined label="Message" v-model="form.message" :rules="messageRules" class="error-message"></v-textarea>
            <div class="text-center">
              <v-btn block depressed color="secondary"
                :loading="sendLoading" :disabled="sendLoading || !valid" @click="sendMessage()">
                Submit
                <template v-slot:loader>
                  <span>Sending...</span>
                </template>
              </v-btn>
            </div>
          </v-form>
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <GmapMap
            :center="{lat: 4.9578874276951295, lng: 115.03049828380634}"
            :zoom="16"
            map-type-id="roadmap"
            class="google-maps">
            <GmapMarker
              :position="{lat: 4.9578874276951295, lng: 115.03049828380634}"
              :clickable="true"/>
          </GmapMap>
        </v-col>
      </v-row>
    </section>

    <section id="working-hours" class="text-center py-15 my-15 bg-primary white--text">
      <h1 class="pb-3">Our working hours</h1>
      <h3>Open everyday!</h3>
      <h3>6a.m. - 5p.m.</h3>
    </section>

    <section id="club-information" class="my-5 mb-15" :class="{ 'px-10': $vuetify.breakpoint.smAndUp, 'px-5': $vuetify.breakpoint.xsOnly }">
      <div class="text-center">
        <h1 class="pb-3 secondary--text">Our club information</h1>
      </div>
      <v-row>
        <v-col cols="12" sm="6">
          <v-card class="pa-3">
            <h2 class="secondary--text pb-3">Emails</h2>
            <h3>info@pmgc.com.bn</h3>
            <h3>admin@pmgc.com.bn</h3>
            <h3>accounts@pmgc.com.bn</h3>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card class="pa-3">
            <h2 class="secondary--text pb-3">Phone</h2>
            <h3>+673 279 1021</h3>
            <h3>+673 279 2888</h3>
            <h3>+673 733 5285 (WhatsApp)</h3>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card class="pa-3">
            <h2 class="secondary--text pb-3">Fax</h2>
            <h3>+673 279 0773</h3>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card class="pa-3">
            <h2 class="secondary--text pb-3">P.O. Box</h2>
            <h3>P.O. Box 1646, Bandar Seri Begawan,<br> BS 8673, Negara Brunei Darussalam</h3>
          </v-card>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
  import apiService from '@/services/api.service'
  import notificationService from '@/services/notification.service'

  export default {
    name: 'Contact',
    data() {
      return {
        valid: false,
        sendLoading: false,
        form: {},
        emailRules: [
          email => !!email || 'Please enter your email',
          email => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email) || 'Please enter  a vaild email'
        ],
        subjectRules: [subject => !!subject || 'Please enter your subject'],
        messageRules: [message => !!message || 'Please enter your message'],
      }
    },
    mounted() {
      const section = this.$router.currentRoute.hash.replace('#', '')
      if (section) this.$nextTick(()=> window.document.getElementById(section).scrollIntoView())
    },
    methods: {
      async sendMessage() {
        this.sendLoading = true
        await apiService.create('api/v1/mailers/contact_form', this.form).then(res => {
          if (res.status == 200) {
            this.sendLoading = false
            this.$refs.contactForm.reset()
            notificationService.success(res.data.message)
          }
        }).catch(err => {
          this.sendLoading = false
          notificationService.error(err.response.data.message)
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '@/scss/style.scss';

  #contact-us {
    .google-maps {
      padding-left: 20px;
      height: 100%;
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
      .google-maps {
        margin: 30px 10px 0 10px;
        height: 400px;
      }
    }
  }

  #working-hours, #club-information {
    h1 {
      font-size: 2em;
    }

    .v-sheet.v-card {
      border-radius: 4px 4px 0 0;
      border-bottom: 3px solid $primary;
    }
  }
</style>
