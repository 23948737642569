<template>
  <v-app-bar app light dense flat elevate-on-scroll :collapse-on-scroll="$vuetify.breakpoint.smAndDown" :prominent="$vuetify.breakpoint.mdAndUp" :color="navbarColor">
    <div class="align-left pl-11 mt-3 d-none d-md-block">
      <v-img contain :src="require('@/assets/logo.png')" transition="scale-transition" width="75"/>
    </div>
    <v-menu v-if="$vuetify.breakpoint.smAndDown" bottom left ref="parentMenuRef">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon :color="navbarItemColor">mdi-menu</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item>
          <v-list-item-title>
            <router-link to="/">
              <v-btn text color="secondary">Home</v-btn>
            </router-link>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <v-menu offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click.stop.prevent text v-bind="attrs" v-on="on" color="secondary">About<v-icon right class="pb-1">mdi-menu-down</v-icon></v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title>
                    <router-link to='/about/committee-members'>
                      <v-btn text color="secondary" @click="closeParentMenu()">Committee members</v-btn>
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <router-link to='/about/reciprocal-clubs'>
                      <v-btn text color="secondary" @click="closeParentMenu()">Reciprocal clubs</v-btn>
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <router-link to="/facilities">
              <v-btn text color="secondary">Facilities</v-btn>
            </router-link>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <v-menu offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click.stop.prevent text v-bind="attrs" v-on="on" color="secondary">Courses<v-icon right class="pb-1">mdi-menu-down</v-icon></v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title>
                    <router-link to='/courses/hole-details'>
                      <v-btn text color="secondary" @click="closeParentMenu()">Hole details</v-btn>
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <router-link to='/courses/rates'>
                      <v-btn text color="secondary" @click="closeParentMenu()">Courses rates</v-btn>
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <router-link to="/news">
              <v-btn text color="secondary">News</v-btn>
            </router-link>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <router-link to="/tournament">
              <v-btn text color="secondary">Tournament</v-btn>
            </router-link>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <router-link to="/contact-us">
              <v-btn text color="secondary">Contact us</v-btn>
            </router-link>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-container v-if="$vuetify.breakpoint.mdAndUp" fill-height fluid>
      <v-row justify="start">
        <div class="d-none d-md-block pl-5">
          <router-link to='/'>
            <v-btn text :color="navbarItemColor">Home</v-btn>
          </router-link>
          <v-menu offset-y open-on-hover>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on" :color="navbarItemColor" class="mb-1">About<v-icon right class="pb-1">mdi-menu-down</v-icon></v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <router-link to='/about/committee-members'>
                    <v-btn text color="secondary">Committee members</v-btn>
                  </router-link>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <router-link to='/about/reciprocal-clubs'>
                    <v-btn text color="secondary">Reciprocal clubs</v-btn>
                  </router-link>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <router-link to='/facilities'>
            <v-btn text :color="navbarItemColor">Facilities</v-btn>
          </router-link>
          <v-menu offset-y open-on-hover>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on" :color="navbarItemColor" class="mb-1">Courses<v-icon right class="pb-1">mdi-menu-down</v-icon></v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <router-link to='/courses/hole-details'>
                    <v-btn text color="secondary">Hole details</v-btn>
                  </router-link>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <router-link to='/courses/rates'>
                    <v-btn text color="secondary">Courses rates</v-btn>
                  </router-link>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <router-link to='/news'>
            <v-btn text :color="navbarItemColor">News</v-btn>
          </router-link>
          <router-link to='/tournament'>
            <v-btn text :color="navbarItemColor">Tournament</v-btn>
          </router-link>
          <router-link to='/contact-us'>
            <v-btn text :color="navbarItemColor">Contact us</v-btn>
          </router-link>
        </div>
      </v-row>
    </v-container>
    <v-spacer></v-spacer>
    <v-img v-if="$vuetify.breakpoint.smAndDown && miniLogo" contain :src="require('@/assets/logo.png')" transition="scale-transition" width="30"/>
  </v-app-bar>
</template>

<script>
  export default {
    name: 'Navbar',
    data() {
      return {
        navbarColor: 'transparent',
        navbarItemColor: 'secondary',
        miniLogo: false
      }
    },
    mounted() {
      window.onscroll = () => this.changeColor()
    },
    methods: {
      changeColor() {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
          this.navbarColor = 'primary'
          this.navbarItemColor = 'white'
          this.miniLogo = true
        } else {
          this.navbarColor = 'transparent'
          this.navbarItemColor = 'secondary'
          this.miniLogo = false
        }
      },
      closeParentMenu() {
        this.$refs.parentMenuRef.isActive = false
      }
    }
  }
</script>
