import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import './scss/style.scss'
import * as VueGooleMaps from 'vue2-google-maps'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'

Vue.config.productionTip = false

Vue.use(VueGooleMaps, {
  load: { key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY }
})

Vue.use(TiptapVuetifyPlugin, { vuetify, iconsGroup: 'mdi' })

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

