<template>
  <section id="admin-login" class="my-10 pb-5">
    <v-container fill-height fluid class="d-none d-sm-flex">
      <v-row justify="center">
        <v-card width="500" class="mx-auto pa-10">
          <h1 class="primary--text">Please enter the following information</h1>
          <div :class="{'ma-2 pa-3': $vuetify.breakpoint.smAndDown}">
            <v-form v-model="valid">
              <v-text-field
                class="pb-3 mt-5" outlined
                type="email" v-model="email" label="Enter admin email" :rules="emailRules"
                placeholder=" " persistent-placeholder required>
              </v-text-field>
              <v-text-field
                class="pb-3" outlined
                v-model="password" :rules="passwordRules" label="Enter admin password"
                placeholder=" " persistent-placeholder
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword" required>
              </v-text-field>
            </v-form>
            <p>Please contact synapsebn support team at <a href="mailto:synapsebn@gmail.com">synapsebn@gmail.com</a> in case you have forgotten your password.</p>
            <v-card-actions>
              <v-btn block depressed color="primary" class="white--text" @click="login()" :loading="loading" :disabled="loading || !valid">
                Login
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-row>
    </v-container>
  </section>
</template>

<script>
  import apiService from '@/services/api.service'
  import notificationService from '@/services/notification.service'

  export default {
    name: 'AdminLogin',
    data() {
      return {
        valid: false,
        loading: false,
        email: '',
        emailRules: [
          email => !!email || 'Please enter admin email here',
          email => (email && /.+@.+/.test(email)) || 'Please check email format (this@example.com)'
        ],
        password: '',
        showPassword: false,
        passwordRules: [
          password => !!password || 'Please enter admin password here'
        ]
      }
    },
    methods: {
      async login() {
        this.loading = true
        await apiService.create('/auth/sign_in', { email: this.email, password: this.password }).then(res => {
          this.$store.commit('setAdminCredentials', res.data.data)
          this.$router.push('/admin/banners')
          notificationService.success('Welcome back!')
        }).catch(err => {
          this.loading = false
          notificationService.error(err.response.data.errors[0] || 'Something went wrong. Please refresh.')
        })
      }
    }
  }
</script>
