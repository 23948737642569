<template>
  <v-chip-group v-if="errors" column class="mb-4">
    <v-chip v-for="(error, index) in errors" :key="index"
      color="danger" text-color="white"
      pill label :ripple="false">
      {{error}}
    </v-chip>
  </v-chip-group>
</template>

<script>
  export default {
    name: 'ErrorMessage',
    props: ['errors']
  }
</script>
