<template>
  <div>
    <section id="memberships-header" class="mb-5">
      <v-row>
        <v-col cols="12" sm="8" class="px-0">
          <h1>Manage memberships</h1>
          <h3 class="gray--text">Change, add or remove memberships</h3>
        </v-col>
        <v-col cols="12" sm="4" class="px-0">
          <v-btn depressed block large color="secondary" @click="toggleMembershipDialog()"><v-icon class="pr-5">mdi-plus</v-icon>Add membership</v-btn>
        </v-col>
      </v-row>
    </section>

    <section id="memberships-table">
      <v-row v-if="$vuetify.breakpoint.smAndUp" class="gray--text">
        <v-col sm="2"><h1>Code</h1></v-col>
        <v-col sm="5"><h1>Title</h1></v-col>
        <v-col sm="5" class="text-center"><h1>Actions</h1></v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card v-for="membership in memberships" :key="membership.id" class="my-5">
        <v-row class="d-flex align-center">
          <v-col cols="12" sm="2"><h3>{{membership.code}}</h3></v-col>
          <v-col cols="12" sm="5"><h3>{{membership.title}}</h3></v-col>
          <v-col cols="12" sm="5" class="pa-0">
            <v-row>
              <v-col cols="6"><v-btn block small outlined color="success" @click="toggleMembershipDialog(membership)">Edit</v-btn></v-col>
              <v-col cols="6"><v-btn block small outlined color="danger" @click="toggleDeleteMembershipDialog(membership)">Remove</v-btn></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-row v-if="memberships.length == 0" >
        <div class="mt-15 mb-5 mx-auto">
          <h1>No membership types to show yet!</h1>
        </div>
      </v-row>
      <div class="text-center mt-10">
        <v-pagination
          v-model="pagination.current_page"
          :length="pagination.total_page"
          :total-visible="7"
          @input="fetchMemberships(pagination.current_page)">
        </v-pagination>
      </div>
    </section>

    <section id="memberships-dialog">
      <v-dialog v-model="membershipDialog" max-width="700" eager>
        <v-card class="pa-5">
          <div class="d-flex align-center">
            <h3>{{ membership.id != null ? 'Edit membership details' : 'Add new membership' }}</h3>
            <v-spacer></v-spacer>
            <v-btn icon @click="toggleMembershipDialog()"><v-icon>mdi-close</v-icon></v-btn>
          </div>
          <v-form ref="MembershipForm" v-model="validMembership">
            <v-row>
              <v-col cols="12" sm="6" class="pl-0 pb-0" :class="{ 'px-0': $vuetify.breakpoint.xsOnly }">
                <v-text-field outlined required v-model="membership.code" :rules="codeRules">
                  <template v-slot:label>Code <span class="red--text"> *</span></template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pr-0 pb-0" :class="{ 'px-0': $vuetify.breakpoint.xsOnly }">
                <v-text-field outlined required v-model="membership.title" :rules="titleRules">
                  <template v-slot:label>Title <span class="red--text"> *</span></template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="3" class="pl-0 pb-0" :class="{ 'pr-2 px-0': $vuetify.breakpoint.xsOnly }">
                <v-text-field
                  class="pricing-field" outlined label="Monthly fee" placeholder="0.00" type="number" step="0.01" min="0.00"
                  :value="membership.monthly_fee"
                  @input="value => membership.monthly_fee = value">
                </v-text-field>
              </v-col>
              <v-col cols="6" sm="3" class="pl-0 pb-0" :class="{ 'pl-2 pr-0': $vuetify.breakpoint.xsOnly }">
                <v-text-field
                  class="pricing-field" outlined placeholder="0.00" type="number" step="0.01" min="0.00"
                  :value="membership.monthly_fee_promo"
                  @input="value => membership.monthly_fee_promo = value"
                  :persistent-hint="true" hint="*Promotion price">
                </v-text-field>
              </v-col>
              <v-col cols="6" sm="3" class="pr-0 pb-0" :class="{ 'pr-2 px-0': $vuetify.breakpoint.xsOnly }">
                <v-text-field
                  class="pricing-field" outlined label="Yearly fee" placeholder="0.00" type="number" step="0.01" min="0.00"
                  :value="membership.yearly_fee"
                  @input="value => membership.yearly_fee = value">
                </v-text-field>
              </v-col>
              <v-col cols="6" sm="3" class="pr-0 pb-0" :class="{ 'pl-2 px-0': $vuetify.breakpoint.xsOnly }">
                <v-text-field
                  class="pricing-field" outlined placeholder="0.00" type="number" step="0.01" min="0.00"
                  :value="membership.yearly_fee_promo"
                  @input="value => membership.yearly_fee_promo = value"
                  :persistent-hint="true" hint="*Promotion price">
                </v-text-field>
              </v-col>
              <v-col cols="6" sm="3" class="pl-0 pb-0" :class="{ 'pr-2 px-0': $vuetify.breakpoint.xsOnly }">
                <v-text-field
                  class="pricing-field" outlined label="Entrance fee" placeholder="0.00" type="number" step="0.01" min="0.00"
                  :value="membership.entrance_fee"
                  @input="value => membership.entrance_fee = value">
                </v-text-field>
              </v-col>
              <v-col cols="6" sm="3" class="pl-0 pb-0" :class="{ 'pl-2 px-0': $vuetify.breakpoint.xsOnly }">
                <v-text-field
                  class="pricing-field" outlined placeholder="0.00" type="number" step="0.01" min="0.00"
                  :value="membership.entrance_fee_promo"
                  @input="value => membership.entrance_fee_promo = value"
                  :persistent-hint="true" hint="*Promotion price">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox v-model="membership.is_included" hide-details color="secondary" class="d-inline-block" label="Include this in home page"></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="px-0 pb-0">
                <v-textarea outlined required v-model="membership.description" :rules="descriptionRules" counter maxlength="200">
                  <template v-slot:label>Description <span class="red--text"> *</span></template>
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <ErrorMessage :errors="membershipErrors"/>
          <v-card-actions class="pa-0">
            <v-spacer></v-spacer>
            <v-btn depressed color="danger" class="white--text px-10" @click="toggleMembershipDialog()">Cancel</v-btn>
            <v-btn depressed color="success" class="px-10"
              :loading="saveLoading" :disabled="saveLoading || !validMembership" @click="saveMembership()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteMembershipDialog" max-width="500px">
        <v-card>
          <v-card-title class="black--text">
            <span><v-icon color="black">mdi-alert-circle-outline</v-icon> Remove  <span class="primary--text">{{membership.title}}</span> from memberships list?</span>
          </v-card-title>
          <v-card-actions class="pb-5 pr-8">
            <v-spacer></v-spacer>
            <v-btn color="danger" text @click="toggleDeleteMembershipDialog()">Cancel</v-btn>
            <v-btn depressed color="success" class="px-10"
              :loading="deleteLoading" :disabled="deleteLoading" @click="deleteMembership(membership.id)">
              Yes, remove
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </section>
  </div>
</template>

<script>
  import apiService from '@/services/api.service'
  import notificationService from '@/services/notification.service'
  import ErrorMessage from '@/components/ErrorMessage'

  export default {
    name: 'AdminMemberships',
    components: { ErrorMessage },
    data() {
      return {
        // Dialogs
        membershipDialog: false,
        deleteMembershipDialog: false,

        // Form
        validMembership: false,
        saveLoading: false,
        deleteLoading: false,
        memberships: [],
        membership: {},
        codeRules: [code => !!code || 'Please enter a membership code'],
        titleRules: [title => !!title || 'Please enter a title'],
        descriptionRules: [title => !!title || 'Please enter some description'],
        membershipErrors: [],
        pagination: {
          current_page: 0,
          per: 0,
          total_count: 0,
          total_page: 0
        }
      }
    },
    filters: {
      toTwoDecimalPlaces(value) {
        if (value && !isNaN(value)) {
          return (Math.round(parseInt(value) * 100) / 100).toFixed(2);
        }
      }
    },
    created() {
      this.fetchMemberships()
    },
    methods: {
      // Toggle dialog methods
      toggleMembershipDialog(membership = null) {
        this.membershipErrors = []
        this.resetForm()
        this.membershipDialog = !this.membershipDialog
        if (membership) this.getMembership(membership.id)
      },
      toggleDeleteMembershipDialog(membership = null) {
        this.deleteMembershipDialog = !this.deleteMembershipDialog
        if (membership) this.membership = membership
        else this.membership = {}
      },

      // API methods
      async fetchMemberships(page = null) {
        await apiService.get(`api/v1/membership_types?page=${page}`).then(res => {
          if (res.status == 200) {
            this.memberships = res.data.data
            if (!page) this.pagination = res.data.pagination
          }
        }).catch(() => notificationService.error('Something went wrong. Please refresh.'))
      },
      async getMembership(membershipId) {
        await apiService.get(`api/v1/membership_types/${membershipId}`).then(res => {
          if (res.status == 200) this.membership = res.data.data
        }).catch(() => notificationService.error('Something went wrong. Please refresh.'))
      },
      async saveMembership() {
        this.saveLoading = true
        this.membershipErrors = []
        if (this.membership.id) {
          await apiService.update(`api/v1/membership_types/${this.membership.id}`, this.membership).then(res => {
            if (res.status == 200) this.cleanUpSuccess(res.data.message)
          }).catch(err => this.cleanUpFailure(err))
        } else {
          await apiService.create('api/v1/membership_types', this.membership).then(res => {
            if (res.status == 200) this.cleanUpSuccess(res.data.message)
          }).catch(err => this.cleanUpFailure(err))
        }
      },
      async deleteMembership(membershipId) {
        this.deleteLoading = true
        await apiService.delete(`api/v1/membership_types/${membershipId}`).then(res => {
          if (res.status == 200) this.cleanUpSuccess(res.data.message)
        }).catch(err => this.cleanUpFailure(err))
      },

      // Clean up methods
      resetForm() {
        this.$refs.MembershipForm.reset()
        this.membership = {}
      },
      cleanUpSuccess(content) {
        notificationService.success(content)
        this.fetchMemberships()
        this.resetLoaders()
        this.membershipDialog = false
        this.deleteMembershipDialog = false
      },
      cleanUpFailure(err) {
        this.resetLoaders()
        this.membershipErrors = err.response.data.errors
        notificationService.error(err.response.data.message || 'Something went wrong. Please refresh.')
      },
      resetLoaders() {
        this.saveLoading = false
        this.deleteLoading = false
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '@/scss/style.scss';

  ::v-deep .pricing-field.v-input {
    font-size: 30px !important;

    @media #{map-get($display-breakpoints, 'xs-only')} {
      font-size: 23px !important;
    }
  }

  #memberships-header {
    h1 {
      font-size: 2em;

      @media #{map-get($display-breakpoints, 'xs-only')} {
        font-size: 1.5em;
      }
    }
  }
</style>
