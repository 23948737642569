<template>
  <section id="tournament-query" class="mt-5 pb-10">
    <v-row :class="{ 'px-10': $vuetify.breakpoint.smAndUp, 'px-5': $vuetify.breakpoint.xsOnly }">
      <v-col cols="12" md="6">
        <h2 class="gray--text mb-5">Step 1: Pick your days (max 3 days) and players</h2>
        <v-text-field type="number" outlined dense placeholder="Number of players" class="mt-3" min="0" v-model="playersCount" @input="calculateFees()"></v-text-field>
        <v-card v-for="day in days" :key="day.id" class="mb-3">
          <v-row>
            <v-col cols="12" sm="4">
              <v-btn outlined depressed block color="primary" :disabled="(daysCount >= 3 && !day.checked) || playersCount == null"
                @click="selectDay(day)" :class="{ 'active' : day.checked }">
                {{day.day}}
              </v-btn>
            </v-col>
            <v-col cols="12" sm="8" class="pl-15">
              <v-radio-group v-model="day.period" row class="mt-1">
                <v-radio :disabled="!day.checked" label="Half day" value="halfDay" @click="calculateFieldClosureFee()"></v-radio>
                <v-radio :disabled="!day.checked" label="Full day" value="fullDay" @click="calculateFieldClosureFee()"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <h2 class="gray--text mb-5">Step 2: Get your admin costs</h2>
        <v-card class="pa-5 mb-3">
          <h1 class="primary--text mb-2">Estimated admin fees:</h1>
          <v-row class="py-1">
            <v-col cols="6" md="8" class="bg-primary text-right">
              <h1 class="white--text fee-text pr-3">Admin Fee:</h1>
            </v-col>
            <v-col cols="6" md="4" class="text-left d-flex align-center">
              <h1 class="primary--text">${{adminFee}}.00</h1>
            </v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6" md="8" class="bg-primary text-right">
              <h1 class="white--text fee-text pr-3">Field Closure Fee:</h1>
            </v-col>
            <v-col cols="6" md="4" class="text-left d-flex align-center">
              <h1 class="primary--text">${{fieldClosureFee}}.00</h1>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="py-1">
            <v-col cols="6" md="8" class="text-right">
              <h1 class="primary--text pr-3">Total:</h1>
            </v-col>
            <v-col cols="6" md="4" class="bg-primary text-left d-flex align-center">
              <h1 class="white--text">${{calculateTotal()}}.00</h1>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="pa-5 gray--text">
          <h1 class="primary--text mb-2">Fee information:</h1>
          <h3 class="mb-5"><span class="secondary--text"><b>Admin fees</b></span> are charges borne for administration and handling of the tournament.</h3>
          <h3 class="mb-5">Tournaments with more than 60 players is required to pay a <span class="secondary--text"><b>field closure fee</b></span> (varies depending on weekdays/weekends and either for half-day/full-day).</h3>
          <h3><span class="secondary--text"><b>Player fees</b></span> are charges borne for each individual player entering the tournament and using PMGC courses. Please enquire from admin regarding this fee.</h3>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
  export default {
    name: 'TournamentRates',
    data() {
      return {
        days: [
          { id: 1, checked: false, day: 'Monday', period: null },
          { id: 2, checked: false, day: 'Tuesday', period: null },
          { id: 3, checked: false, day: 'Wednesday', period: null },
          { id: 4, checked: false, day: 'Thursday', period: null },
          { id: 5, checked: false, day: 'Friday', period: null },
          { id: 6, checked: false, day: 'Saturday', period: null },
          { id: 7, checked: false, day: 'Sunday', period: null }
        ],
        daysCount: 0,
        playersCount: null,
        adminFee: 0,
        fieldClosureFee: 0,
        total: 0
      }
    },
    methods: {
      selectDay(day) {
        day.checked = !day.checked
        if (day.checked) this.daysCount ++
        else {
          this.daysCount --
          day.period = null
        }
        this.calculateFees()
      },
      calculateFees() {
        this.calculateAdminFee()
        this.calculateFieldClosureFee()
      },
      calculateAdminFee() {
        if (this.playersCount <= 50) this.adminFee = 50 * this.daysCount
        else this.adminFee = 100 * this.daysCount
      },
      calculateFieldClosureFee() {
        const chosenDates = this.days.filter(day => day.checked)
        if (this.playersCount >= 60) {
          let totalFieldClosureFees = 0
          chosenDates.forEach(date => {
            switch (date.period) {
              case 'halfDay':
                totalFieldClosureFees += (date.day == 'Saturday' || date.day == 'Sunday') ? 150 : 100
                break
              case 'fullDay':
                totalFieldClosureFees += (date.day == 'Saturday' || date.day == 'Sunday') ? 200 : 150
                break
            }
          })
          this.fieldClosureFee = totalFieldClosureFees
        } else this.fieldClosureFee = 0
      },
      calculateTotal() {
        return this.adminFee + this.fieldClosureFee
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '@/scss/style.scss';

  ::v-deep .v-messages {
    min-height: 0px;
  }

  #tournament-query {
    .active {
      background-color: $primary;
      color: white !important;
    }

    .theme--light.v-divider {
      border-color: $secondary !important;
      max-width: 100%;
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
      .fee-text {
        font-size: 20px;
      }
    }
  }
</style>
