<template>
  <section id="hole-details" class="mt-5 pb-10">
    <v-row :class="{ 'px-10': $vuetify.breakpoint.smAndUp, 'px-5': $vuetify.breakpoint.xsOnly }">
      <v-col v-for="course in courses" :key="course.id" cols="12" sm="6" md="4">
        <v-dialog v-model="course.openModal" width="500">
          <template v-slot:activator="{ on, attrs }">
            <a v-bind="attrs" v-on="on" class="course-card">
              <v-card flat v-ripple>
                <v-img :src="course.featured_image_url || require('@/assets/default-landscape.png')" width="auto" :aspect-ratio="16/9">
                  <template v-slot:placeholder>
                    <v-container fill-height class="d-flex align-center justify-center">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-container>
                  </template>
                </v-img>
                <div class="secondary--text d-flex align-center pa-2">
                  <h1 class="secondary--text">{{course.name}}</h1>
                  <v-spacer></v-spacer>
                  <v-icon class="pt-1" color="secondary">mdi-magnify</v-icon>
                </div>
                <v-divider class="mx-4"></v-divider>
              </v-card>
            </a>
          </template>

          <v-card :class="{ 'px-10 pb-8 pt-5': $vuetify.breakpoint.smAndUp, 'px-5 pb-4 pt-3': $vuetify.breakpoint.xsOnly }">
            <div class="d-flex align-center pb-3">
              <h1>{{course.name}}</h1>
              <v-spacer></v-spacer>
              <v-btn icon @click="course.openModal = false"><v-icon>mdi-close</v-icon></v-btn>
            </div>
            <p>{{course.description}}</p>
            <v-img :src="course.featured_image_url || require('@/assets/default-landscape.png')" class="hole-image" :aspect-ratio="16/9" width="auto">
              <template v-slot:placeholder>
                <v-container fill-height class="d-flex align-center justify-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-container>
              </template>
            </v-img>
            <v-img :src="course.diagram_url || require('@/assets/default-landscape.png')" class="mt-5 hole-image" width="auto" :aspect-ratio="16/9">
              <template v-slot:placeholder>
                <v-container fill-height class="d-flex align-center justify-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-container>
              </template>
            </v-img>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row v-if="courses.length == 0" >
      <div class="my-15 mx-auto">
        <h1>No courses to show yet!</h1>
      </div>
    </v-row>
  </section>
</template>

<script>
  import apiService from '@/services/api.service'

  export default {
    name: 'CoursesDetails',
    data() {
      return {
        courses: []
      }
    },
    created() {
      this.fetchCourses()
    },
    methods: {
      async fetchCourses() {
        await apiService.get('api/v1/courses?no_paginate=true').then(res => {
          if (res.status == 200) this.courses = res.data.data
        }).catch(() => {})
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '@/scss/style.scss';

  #hole-details {
    h1 {
      font-size: 2em;
      line-height: 40px;
    }

    .course-card {
      .v-sheet.v-card {
        border-radius: 4px 4px 0 0;
        border-bottom: 3px solid $primary;
      }
    }

    .hole-image {
      border-radius: 4px;

      .hole-ratio-box {
        width: 100%;
        padding-bottom: 56.25%;
      }
    }
  }
</style>
