<template>
  <div>
    <section id="post-header">
      <v-row class="mb-5">
        <v-col cols="12" sm="4" class="d-flex align-center px-0">
          <v-btn text color="primary" @click="returnToList()"><v-icon class="pr-3">mdi-arrow-left</v-icon>Back to list</v-btn>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.smAndUp" cols="12" sm="4">
          <v-btn depressed large block outlined color="success"
            :loading="saveLoading" :disabled="saveLoading || !validPost || !post.content" @click="savePost('draft')">
            Save as draft
          </v-btn>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.smAndUp" cols="12" sm="4" class="pr-0">
          <v-btn depressed large block color="success"
            :loading="saveLoading" :disabled="saveLoading || !validPost || !post.content" @click="savePost('publish')">
            Publish post
          </v-btn>
        </v-col>
      </v-row>
    </section>

    <section id="post-details">
      <ErrorMessage :errors="postErrors"/>
      <v-form ref="PostForm" v-model="validPost">
        <v-text-field outlined v-model="post.title" :rules="titleRules" required>
          <template v-slot:label>Title <span class="red--text"> *</span></template>
        </v-text-field>
        <small>Content<span class="red--text"> *</span></small>
        <tiptap-vuetify v-model="post.content" :extensions="extensions" class="mb-8 content-textarea" :card-props="{ flat: true }"/>
        <v-row>
          <v-col cols="12" sm="6" :class="{ 'px-0': $vuetify.breakpoint.xsOnly, 'pl-0': $vuetify.breakpoint.smAndUp }">
            <small>Gallery (recommended 4 to 5 pictures only)</small>
            <v-card v-for="(gallery, index) in post.galleries" :key="index" flat class="gallery-card mt-1 mb-2">
              <v-row>
                <v-col cols="9">
                  <a @click="toggleGalleryImageDialog(gallery)">
                    <h2 class="gray--text">{{gallery.name || `new_image_${index + 1}`}}</h2>
                  </a>
                </v-col>
                <v-col cols="3" class="text-right">
                  <v-btn icon color="danger" @click="deleteGalleryImage(gallery, index)"><v-icon>mdi-delete</v-icon></v-btn>
                </v-col>
              </v-row>
            </v-card>
            <input ref="GalleryFileInput" type="file" style="display: none;" @change="onFileSelect"/>
            <v-btn block depressed color="secondary" class="mt-1" @click="$refs.GalleryFileInput.click(), (cropperTarget = 'gallery')">Add image</v-btn>
          </v-col>
          <v-col cols="12" sm="6" :class="{ 'px-0': $vuetify.breakpoint.xsOnly, 'pr-0': $vuetify.breakpoint.smAndUp }">
            <small>Featured image</small>
            <v-img :lazy-src="post.featured_image_url || require('@/assets/default-landscape.png')"
              :src="post.featured_image_url || require('@/assets/default-landscape.png')" class="mx-auto post-image mt-1" :aspect-ratio="16/9" width="auto">
              <template v-slot:placeholder>
                <v-container fill-height class="d-flex align-center justify-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-container>
              </template>
            </v-img>
            <input ref="ImageFileInput" type="file" style="display: none;" @change="onFileSelect"/>
            <v-btn block outlined class="mt-3" color="success" @click="$refs.ImageFileInput.click(), (cropperTarget = 'featured')">{{ post.id ? 'Change' : 'Upload' }}</v-btn>
          </v-col>
          <v-dialog v-model="cropperDialog" width="500">
            <v-card class="pa-3">
              <VueCropper v-show="selectedFile" :aspectRatio="16/9" ref="CropperRef" :src="selectedFile" alt="Source Image"></VueCropper>
              <v-card-actions class="px-0">
                <v-spacer></v-spacer>
                <v-btn color="danger" text @click="cropperDialog = false">Cancel</v-btn>
                <v-btn depressed class="primary" @click="cropImage(), (cropperDialog = false)">Crop</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-form>
      <v-row v-if="$vuetify.breakpoint.xsOnly">
        <v-col cols="6" class="pl-0">
          <v-btn depressed large block outlined color="success"
            :loading="saveLoading" :disabled="saveLoading || !validPost" @click="savePost('draft')">
            Draft
          </v-btn>
        </v-col>
        <v-col cols="6" class="pr-0">
          <v-btn depressed large block color="success"
            :loading="saveLoading" :disabled="saveLoading || !validPost" @click="savePost('publish')">
            Post
          </v-btn>
        </v-col>
      </v-row>
    </section>

    <v-dialog v-model="galleryImageDialog" max-width="500px">
      <v-card class="pa-3">
        <v-row>
          <v-col cols="8" class="pa-0 mt-2 d-flex align-center">
            <h3>View image</h3>
          </v-col>
          <v-col cols="4" class="text-right pr-0">
            <v-btn icon @click="toggleGalleryImageDialog()"><v-icon>mdi-close</v-icon></v-btn>
          </v-col>
        </v-row>
        <v-img :src="showImage.url || require('@/assets/default-landscape.png')" class="mx-auto post-image mt-1">
          <div class="post-ratio-box"></div>
          <template v-slot:placeholder>
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </template>
        </v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import VueCropper from 'vue-cropperjs'
  import 'cropperjs/dist/cropper.css'
  import apiService from '@/services/api.service'
  import notificationService from '@/services/notification.service'
  import {
    TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Paragraph, BulletList,
    OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History
  } from 'tiptap-vuetify'
  import ErrorMessage from '@/components/ErrorMessage'

  export default {
    components: { VueCropper, TiptapVuetify, ErrorMessage },
    data() {
      return {
        // Dialogs
        cropperDialog: false,
        galleryImageDialog: false,

        // Form
        validPost: false,
        saveLoading: false,
        post: { galleries: [] },
        showImage: {},
        titleRules: [title => !!title || 'Please enter a title'],
        contentRules: [content => !!content || 'Please add some content'],
        toBeDeleted: [],
        postErrors: [],
        selectedFile: '',
        cropperTarget: null,

        // WYSIWYG editor
        extensions: [
          History,
          Blockquote,
          Link,
          Underline,
          Strike,
          Italic,
          ListItem,
          BulletList,
          OrderedList,
          [Heading, {
            options: {
              levels: [1, 2, 3]
            }
          }],
          Bold,
          HorizontalRule,
          Paragraph,
          HardBreak
        ],
      }
    },
    created() {
      if (this.$route.params.id) this.getPost()
    },
    methods: {
      // Toggle dialog methods
      toggleGalleryImageDialog(image = null) {
        this.galleryImageDialog = !this.galleryImageDialog
        if (image) this.showImage = image
      },

      // Cropper methods
      cropImage() {
        if (this.cropperTarget == 'featured') {
          this.post.featured_image_url = this.$refs.CropperRef.getCroppedCanvas().toDataURL()
          this.$refs.CropperRef.getCroppedCanvas().toBlob(blob => {
            this.post.featured_image = blob
          })
        } else {
          this.$refs.CropperRef.getCroppedCanvas().toBlob(blob => {
            if (!('galleries' in this.post)) this.post.galleries = []
            this.post.galleries.push({ name: '', blob, url: this.$refs.CropperRef.getCroppedCanvas().toDataURL() })
          })
        }
      },
      onFileSelect(e) {
        const file = e.target.files[0]
        if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
          notificationService.error('The picture type must be one of jpeg, jpg or png')
          return false
        }
        if (typeof FileReader !== 'function') {
          notificationService.error('Sorry, FileReader API not supported. Please try a different browser')
          return false
        }

        this.cropperDialog = true
        const reader = new FileReader()
        reader.onload = event => {
          this.selectedFile = event.target.result
          this.$refs.CropperRef.replace(this.selectedFile)
        }
        reader.readAsDataURL(file)
      },

      // API methods
      async getPost() {
        await apiService.get(`api/v1/posts/${this.$route.params.id}`).then(res => {
          if (res.status == 200) this.post = res.data.data
        }).catch(() => notificationService.error('Something went wrong. Please refresh.'))
      },
      savePost(state) {
        let formData = new FormData()
        if (this.post.title) formData.append('title', this.post.title)
        if (this.post.content) formData.append('content', this.post.content)
        if (this.post.featured_image) formData.append('featured_image', this.post.featured_image)
        if (this.post.galleries) this.post.galleries.forEach(gallery => {
          if (gallery.blob) formData.append('galleries[]', gallery.blob)
        })
        formData.append('is_published', state === 'draft' ? false : true)
        formData.append('to_be_deleted', this.toBeDeleted)

        if (this.post.id) this.updatePost(formData, this.post.id)
        else this.createPost(formData)
      },
      async createPost(formData) {
        await apiService.create('api/v1/posts', formData).then(res => {
          if (res.status == 200) this.cleanUpSuccess(res.data.message)
        }).catch(err => this.cleanUpFailure(err))
      },
      async updatePost(formData, postId) {
        await apiService.update(`api/v1/posts/${postId}`, formData).then(res => {
          if (res.status == 200) this.cleanUpSuccess(res.data.message)
        }).catch(err => this.cleanUpFailure(err))
      },
      deleteGalleryImage(gallery, index) {
        if (gallery.id) {
          this.post.galleries = this.post.galleries.filter(item => item.id != gallery.id)
          this.toBeDeleted.push(gallery.id)
        } else {
          this.post.galleries = this.post.galleries.filter((item, i) => i != index)
        }
      },

      // Clean up methods
      cleanUpSuccess(content) {
        notificationService.success(content)
        this.$router.push('/admin/news')
        this.selectedFile = null
        this.cropperTarget = null
      },
      cleanUpFailure(err) {
        this.saveLoading = false
        this.postErrors = err.response.data.errors
        notificationService.error(err.response.data.message || 'Something went wrong. Please refresh.')
      },

      // Others
      returnToList() {
        if (this.post.title || this.post.content) {
          const answer = window.confirm('Have you saved your current progress yet?');
          if (answer) this.$router.push('/admin/news')
        } else this.$router.push('/admin/news')
      }
    }
  }
</script>

<style lang="scss">
  @import '@/scss/style.scss';

  .gallery-card {
    border: 1px solid $black !important;
  }

  .content-textarea {
    border: 1px solid $gray;
    border-radius: 4px;

    .tiptap-vuetify-editor__toolbar > .v-toolbar {
      border-radius: 4px 4px 0 0 !important;
    }
  }

  .post-image {
    border: 1px solid $black;
    border-radius: 4px;

    .post-ratio-box {
      width: 100%;
      padding-bottom: 56.25%;
    }
  }
</style>
