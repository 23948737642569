<template>
  <div class="mt-10">
    <section id="member-selection">
      <v-tabs v-model="tabs" background-color="primary" :fixed-tabs="$vuetify.breakpoint.mdAndUp" center-active dark show-arrows class="rounded-lg" :class="{ 'px-10': $vuetify.breakpoint.smAndUp, 'px-5': $vuetify.breakpoint.xsOnly }">
        <v-tab>Non-members</v-tab>
        <v-tab>Guest members</v-tab>
        <v-tab>Members</v-tab>
        <v-tab>Reciprocal members</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tabs" :class="{ 'px-10': $vuetify.breakpoint.smAndUp, 'px-5': $vuetify.breakpoint.xsOnly }" class="pb-10">
        <v-tab-item v-for="(rate, index) in rates" :key="index" class="mt-5">
          <template v-if="rate.memberType == 'nonMembers' || rate.memberType == 'guestMembers'">
            <h1 class="text-center gray--text">Weekdays Only<br>(Monday - Friday)</h1>
            <v-row>
              <v-col cols="12" sm="6" lg="4" offset-lg="2" class="text-center gray--text">
                <PricingCard title="Play for 9 holes" :pricing="rate.pricing.nineHoleWeekday"/>
              </v-col>
              <v-col cols="12" sm="6" lg="4" class="text-center gray--text">
                <PricingCard title="Play for 18 holes" :pricing="rate.pricing.eighteenHoleWeekday"/>
              </v-col>
            </v-row>
          </template>

          <template v-if="rate.memberType == 'members' || rate.memberType == 'reciprocalMembers'">
            <v-row>
              <v-col cols="12" sm="8" md="6" lg="4" offset-sm="2" offset-md="3" offset-lg="4" class="text-center gray--text">
                <h1>All days<br>(Monday - Sunday)</h1>
                <PricingCard title="Play for 9 or 18 holes" :pricing="rate.pricing.allDays" :memberType="rate.memberType"/>
              </v-col>
            </v-row>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </section>
  </div>
</template>

<script>
  import PricingCard from '@/components/PricingCard'

  export default {
    name: 'CoursesRates',
    components: { PricingCard },
    data() {
      return {
        tabs: null,
        useBuggy: false,
        buggyCount: 0,
        rates: [
          {
            memberType: 'nonMembers',
            pricing: {
              nineHoleWeekday: {
                levy: { price: 3.00, isIncluded: false },
                buggy: { price: 20.00, isIncluded: false },
                greenPrice: 20.00
              },
              nineHoleWeekend: {
                levy: { price: 3.00, isIncluded: false },
                buggy: { price: 20.00, isIncluded: false },
                greenPrice: 30.00
              },
              eighteenHoleWeekday: {
                levy: { price: 3.00, isIncluded: false },
                buggy: { price: 20.00, isIncluded: false },
                greenPrice: 35.00
              },
              eighteenHoleWeekend: {
                levy: { price: 3.00, isIncluded: false },
                buggy: { price: 20.00, isIncluded: false },
                greenPrice: 45.00
              },
              buggyPrice: 40.00
            }
          },
          {
            memberType: 'guestMembers',
            pricing: {
              nineHoleWeekday: {
                levy: { price: 3.00, isIncluded: false },
                buggy: { price: 15.00, isIncluded: false },
                greenPrice: 10.00
              },
              nineHoleWeekend: {
                levy: { price: 3.00, isIncluded: false },
                buggy: { price: 15.00, isIncluded: false },
                greenPrice: 15.00
              },
              eighteenHoleWeekday: {
                levy: { price: 3.00, isIncluded: false },
                buggy: { price: 15.00, isIncluded: false },
                greenPrice: 15.00
              },
              eighteenHoleWeekend: {
                levy: { price: 3.00, isIncluded: false },
                buggy: { price: 15.00, isIncluded: false },
                greenPrice: 30.00
              },
              buggyPrice: 30.00
            }
          },
          {
            memberType: 'members',
            pricing: {
              allDays: {
                levy: { price: 3.00, isIncluded: false },
                buggy: { price: 13.00, isIncluded: false },
                greenPrice: 0.00
              },
              buggyPrice: 26.00
            }
          },
          {
            memberType: 'reciprocalMembers',
            pricing: {
              allDays: {
                levy: { price: 5.00, isIncluded: false },
                buggy: { price: 13.00, isIncluded: false },
                greenPrice: 0.00
              },
              buggyPrice: 26.00
            }
          }
        ]
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '@/scss/style.scss';

  #member-selection {
    .buggy-query {
      @media #{map-get($display-breakpoints, 'sm-only')} {
        font-size: 18px;
      }

      @media #{map-get($display-breakpoints, 'xs-only')} {
        line-height: 50px;
      }

      .large-text {
        font-size: 30px;
      }
    }
  }

  ::v-deep .v-text-field{
    width: 50px;
    font-size: 30px;

    input {
      text-align: center;
    }
  }

  ::v-deep .v-tabs:not(.v-tabs--vertical) .v-tab {
    white-space: inherit;
  }

  ::v-deep .theme--light.v-icon {
    color: $secondary;
  }
</style>
