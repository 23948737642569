<template>
  <div>
    <section id="members-header">
      <v-row class="mb-5">
        <v-col cols="12" sm="8" class="px-0">
          <h1>Manage committee members</h1>
          <h3 class="gray--text">Change, add or remove committee members</h3>
        </v-col>
        <v-col cols="12" sm="4" class="px-0">
          <v-btn block depressed large color="secondary" @click="toggleMemberDialog()"><v-icon class="pr-5">mdi-plus</v-icon>Add member</v-btn>
        </v-col>
      </v-row>
    </section>

    <section id="members-table">
      <v-row v-if="$vuetify.breakpoint.smAndUp" class="gray--text">
        <v-col sm="3"><h1>Name</h1></v-col>
        <v-col sm="4"><h1>Title</h1></v-col>
        <v-col sm="5" class="text-center"><h1>Actions</h1></v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card v-for="member in committeeMembers" :key="member.id" class="my-5">
        <v-row class="d-flex align-center">
          <v-col cols="12" sm="3"><h3>{{member.name}}</h3></v-col>
          <v-col cols="12" sm="4"><h3>{{member.title}}</h3></v-col>
          <v-col cols="12" sm="5" class="pa-0">
            <v-row>
              <v-col cols="6"><v-btn small block outlined color="success" @click="toggleMemberDialog(member)">Edit</v-btn></v-col>
              <v-col cols="6"><v-btn small block outlined color="danger" @click="toggleDeleteMemberDialog(member)">Remove</v-btn></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-row v-if="committeeMembers.length == 0" >
        <div class="mt-15 mb-5 mx-auto">
          <h1>No committee members to show yet!</h1>
        </div>
      </v-row>
      <div class="text-center mt-10">
        <v-pagination
          v-model="pagination.current_page"
          :length="pagination.total_page"
          :total-visible="7"
          @input="fetchCommitteeMembers(pagination.current_page)">
        </v-pagination>
      </div>
    </section>

    <section id="members-dialog">
      <v-dialog v-model="memberDialog" max-width="700" eager>
        <v-card class="pa-5">
          <div class="d-flex align-center">
            <h3>{{ member.id != null ? 'Edit member details' : 'Add new member' }}</h3>
            <v-spacer></v-spacer>
            <v-btn icon @click="toggleMemberDialog()"><v-icon>mdi-close</v-icon></v-btn>
          </div>
          <v-form ref="MemberForm" v-model="validMember">
            <v-row>
              <v-col cols="12" sm="4" :class="{ 'px-0': $vuetify.breakpoint.xsOnly }">
                <v-img :src="member.image_url || require('@/assets/default-passport.png')" class="mx-auto member-image" :aspect-ratio="7/9" width="auto">
                  <template v-slot:placeholder>
                    <v-container fill-height class="d-flex align-center justify-center">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-container>
                  </template>
                </v-img>
                <input ref="FileInput" type="file" style="display: none;" @change="onFileSelect"/>
                <v-dialog v-model="cropperDialog" width="500">
                  <v-card class="pa-3">
                    <VueCropper v-show="selectedFile" :aspectRatio="7/9" ref="CropperRef" :src="selectedFile" alt="Source Image"></VueCropper>
                    <v-card-actions class="px-0">
                      <v-spacer></v-spacer>
                      <v-btn color="danger" text @click="cropperDialog = false">Cancel</v-btn>
                      <v-btn depressed class="primary" @click="cropImage(), (cropperDialog = false)">Crop</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="8" :class="{ 'px-0': $vuetify.breakpoint.xsOnly }">
                <v-text-field outlined v-model="member.name" :rules="nameRules" required>
                  <template v-slot:label>Name <span class="red--text"> *</span></template>
                </v-text-field>
                <v-text-field outlined v-model="member.title" :rules="titleRules" required>
                  <template v-slot:label>Title <span class="red--text"> *</span></template>
                </v-text-field>
                <p v-if="memberErrors.length == 0" class="gray--text mb-0">*Please make sure that the name and title details are correctly entered!</p>
                <ErrorMessage :errors="memberErrors"/>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col cols="12" sm="4" :class="{ 'px-0': $vuetify.breakpoint.xsOnly }">
              <v-btn block outlined depressed color="secondary" class="v-btn__secondary" @click="$refs.FileInput.click()">Change</v-btn>
            </v-col>
            <v-col cols="12" sm="4" :class="{ 'px-0': $vuetify.breakpoint.xsOnly }">
              <v-btn block depressed color="danger" class="white--text" @click="toggleMemberDialog()">Cancel</v-btn>
            </v-col>
            <v-col cols="12" sm="4" :class="{ 'px-0': $vuetify.breakpoint.xsOnly }">
              <v-btn depressed block color="success" class="px-10"
                :loading="saveLoading" :disabled="saveLoading || !validMember" @click="saveCommitteeMember()">
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteMemberDialog" max-width="500px">
        <v-card>
          <v-card-title class="black--text">
            <span><v-icon color="black">mdi-alert-circle-outline</v-icon> Remove <span class="primary--text">{{member.name}}</span> from current committee members list?</span>
          </v-card-title>
          <v-card-actions class="pb-5 pr-8">
            <v-spacer></v-spacer>
            <v-btn color="danger" text @click="toggleDeleteMemberDialog()">Cancel</v-btn>
            <v-btn depressed color="success" class="px-10"
              :loading="deleteLoading" :disabled="deleteLoading" @click="deleteCommitteeMember(member.id)">
              Yes, remove
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </section>
  </div>
</template>

<script>
  import VueCropper from 'vue-cropperjs'
  import 'cropperjs/dist/cropper.css'
  import apiService from '@/services/api.service'
  import notificationService from '@/services/notification.service'
  import ErrorMessage from '@/components/ErrorMessage'

  export default {
    name: 'AdminCommitteeMembers',
    components: { VueCropper, ErrorMessage },
    data() {
      return {
        // Dialogs
        memberDialog: false,
        cropperDialog: false,
        deleteMemberDialog: false,

        // Form
        validMember: false,
        saveLoading: false,
        deleteLoading: false,
        committeeMembers: [],
        member: { name: null, title: null, image: null },
        nameRules: [
          name => !!name || 'Please enter a name'
        ],
        titleRules: [
          title => !!title || 'Please enter a title'
        ],
        memberErrors: [],
        selectedFile: '',
        pagination: {
          current_page: 0,
          per: 0,
          total_count: 0,
          total_page: 0
        }
      }
    },
    created() {
      this.fetchCommitteeMembers()
    },
    methods: {
      // Toggle dialog methods
      toggleMemberDialog(member = null) {
        this.memberErrors = []
        this.resetForm()
        this.memberDialog = !this.memberDialog
        if (member) this.getCommitteeMember(member.id)
      },
      toggleDeleteMemberDialog(member = null) {
        this.deleteMemberDialog = !this.deleteMemberDialog
        if (member) this.member = member
        else this.member = {}
      },

      // Cropper methods
      cropImage() {
        this.member.image_url = this.$refs.CropperRef.getCroppedCanvas().toDataURL()
        this.$refs.CropperRef.getCroppedCanvas().toBlob(blob => {
          this.member.image = blob
        })
      },
      onFileSelect(e) {
        const file = e.target.files[0]
        if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
          notificationService.danger('The picture type must be one of jpeg, jpg or png')
          return false
        }
        if (typeof FileReader !== 'function') {
          notificationService.error('Sorry, FileReader API not supported. Please try a different browser')
          return false
        }

        this.cropperDialog = true
        const reader = new FileReader()
        reader.onload = event => {
          this.selectedFile = event.target.result
          this.$refs.CropperRef.replace(this.selectedFile)
        }
        reader.readAsDataURL(file)
      },

      // API methods
      async fetchCommitteeMembers(page = null) {
        await apiService.get(`api/v1/committee_members?page=${page}`).then(res => {
          if (res.status == 200) {
            this.committeeMembers = res.data.data
            if (!page) this.pagination = res.data.pagination
          }
        }).catch(() => notificationService.error('Something went wrong. Please refresh.'))
      },
      async getCommitteeMember(memberId) {
        await apiService.get(`api/v1/committee_members/${memberId}`).then(res => {
          if (res.status == 200) this.member = res.data.data
        }).catch(() => notificationService.error('Something went wrong. Please refresh.'))
      },
      saveCommitteeMember() {
        this.saveLoading = true
        this.memberErrors = []
        let formData = new FormData()
        if (this.member.name) formData.append('name', this.member.name)
        if (this.member.title) formData.append('title', this.member.title)
        if (this.member.image) formData.append('image', this.member.image)

        if (this.member.id) this.updateCommitteeMember(formData, this.member.id)
        else this.createCommitteeMember(formData)
      },
      async createCommitteeMember(formData) {
        await apiService.create('api/v1/committee_members', formData).then(res => {
          if (res.status == 200) this.cleanUpSuccess(res.data.message)
        }).catch(err => this.cleanUpFailure(err))
      },
      async updateCommitteeMember(formData, memberId) {
        await apiService.update(`api/v1/committee_members/${memberId}`, formData).then(res => {
          if (res.status == 200) this.cleanUpSuccess(res.data.message)
        }).catch(err => this.cleanUpFailure(err))
      },
      async deleteCommitteeMember(memberId) {
        this.deleteLoading = true
        await apiService.delete(`api/v1/committee_members/${memberId}`).then(res => {
          if (res.status == 200) this.cleanUpSuccess(res.data.message)
        }).catch(err => this.cleanUpFailure(err))
      },

      // Clean up methods
      resetForm() {
        this.$refs.MemberForm.reset()
        this.member = {}
      },
      cleanUpSuccess(content) {
        notificationService.success(content)
        this.fetchCommitteeMembers()
        this.resetLoaders()
        this.selectedFile = null
        this.memberDialog = false
        this.deleteMemberDialog = false
      },
      cleanUpFailure(err) {
        this.resetLoaders()
        this.memberErrors = err.response.data.errors
        notificationService.error(err.response.data.message || 'Something went wrong. Please refresh.')
      },
      resetLoaders() {
        this.saveLoading = false
        this.deleteLoading = false
      }
    }
  }
</script>

<style lang="scss">
  @import '@/scss/style.scss';

  #members-header {
    h1 {
      font-size: 2em;

      @media #{map-get($display-breakpoints, 'xs-only')} {
        font-size: 1.5em;
      }
    }
  }

  .member-image {
    border: 1px solid $black;
    border-radius: 4px;

    .member-ratio-box {
      width: 100%;
      height: 128.57%;
    }
  }
</style>
