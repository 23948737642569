<template>
  <div>
    <section id="clubs-header">
      <v-row class="mb-5">
        <v-col cols="12" sm="8" class="px-0">
          <h1>Manage reciprocal clubs</h1>
          <h3 class="gray--text">Change, add or remove reciprocal clubs</h3>
        </v-col>
        <v-col cols="12" sm="4" class="px-0">
          <v-btn depressed block large color="secondary" @click="toggleClubDialog()"><v-icon class="pr-5">mdi-plus</v-icon>Add club</v-btn>
        </v-col>
      </v-row>
    </section>

    <section id="clubs-table">
      <v-select :items="countries" v-model="selectedCountry" hide-details dense label="Pick a country" outlined @input="fetchReciprocalClubs()"></v-select>
      <v-row v-if="$vuetify.breakpoint.smAndUp" class="gray--text">
        <v-col sm="3"><h1>Name</h1></v-col>
        <v-col sm="4"><h1>Location</h1></v-col>
        <v-col sm="5" class="text-center"><h1>Actions</h1></v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card v-for="club in reciprocalClubs" :key="club.id" class="my-5">
        <v-row class="d-flex align-center">
          <v-col cols="12" sm="3"><h3>{{club.name}}</h3></v-col>
          <v-col cols="12" sm="4"><h3>{{club.location}}</h3></v-col>
          <v-col cols="12" sm="5" class="pa-0">
            <v-row>
              <v-col cols="6"><v-btn small block outlined color="success" @click="toggleClubDialog(club)">Edit</v-btn></v-col>
              <v-col cols="6"><v-btn small block outlined color="danger" @click="toggleDeleteClubDialog(club)">Remove</v-btn></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-row v-if="reciprocalClubs.length == 0" >
        <div class="mt-15 mb-5 mx-auto">
          <h1>No reciprocal clubs to show yet!</h1>
        </div>
      </v-row>
      <div class="text-center mt-10">
        <v-pagination
          v-model="pagination.current_page"
          :length="pagination.total_page"
          :total-visible="7"
          @input="fetchReciprocalClubs(pagination.current_page)">
        </v-pagination>
      </div>
    </section>

    <section id="clubs-dialog">
      <v-dialog v-model="clubDialog" max-width="700" eager>
        <v-card class="pa-5">
          <div class="d-flex align-center">
            <h3>{{ club.id ? 'Edit club details' : 'Add new club' }}</h3>
            <v-spacer></v-spacer>
            <v-btn icon @click="toggleClubDialog()"><v-icon>mdi-close</v-icon></v-btn>
          </div>
          <v-form ref="ClubForm" v-model="validClub">
            <v-row v-if="!club.id">
              <v-col cols="12" class="px-0">
                <v-select hide-details :items="countries" v-model="club.country" required hide-detail outlined>
                  <template v-slot:label>Pick a country <span class="red--text"> *</span></template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="pl-0 pb-0" :class="{ 'px-0': $vuetify.breakpoint.xsOnly }">
                <v-text-field outlined v-model="club.name" :rules="nameRules" required>
                  <template v-slot:label>Name <span class="red--text"> *</span></template>
                </v-text-field>
                <v-text-field outlined v-model="club.location" :rules="locationRules" required>
                  <template v-slot:label>Location <span class="red--text"> *</span></template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pr-0" :class="{ 'px-0': $vuetify.breakpoint.xsOnly }">
                <v-text-field outlined v-model="club.telephone" :rules="telephoneRules" required>
                  <template v-slot:label>Telephone no. <span class="red--text"> *</span></template>
                </v-text-field>
                <v-text-field outlined v-model="club.fax" :rules="faxRules" required>
                  <template v-slot:label>Fax no. <span class="red--text"> *</span></template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <ErrorMessage :errors="clubErrors"/>
          <v-card-actions class="pa-0">
            <v-spacer></v-spacer>
            <v-btn depressed color="danger" class="white--text px-10" @click="toggleClubDialog()">Cancel</v-btn>
            <v-btn depressed color="success" class="px-10"
              :loading="saveLoading" :disabled="saveLoading || !validClub || !club.country" @click="saveReciprocalClub()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteClubDialog" max-width="500px">
        <v-card>
          <v-card-title class="black--text">
            <span><v-icon color="black">mdi-alert-circle-outline</v-icon> Remove <span class="primary--text">{{club.name}}</span> from reciprocal clubs list?</span>
          </v-card-title>
          <v-card-actions class="pb-5 pr-8">
            <v-spacer></v-spacer>
            <v-btn color="danger" text @click="toggleDeleteClubDialog()">Cancel</v-btn>
            <v-btn depressed color="success" class="px-10"
              :loading="deleteLoading" :disabled="deleteLoading" @click="deleteReciprocalClub(club.id)">
              Yes, remove
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </section>
  </div>
</template>

<script>
  import apiService from '@/services/api.service'
  import notificationService from '@/services/notification.service'
  import ErrorMessage from '@/components/ErrorMessage'

  export default {
    name: 'AdminReciprocalClubs',
    components: { ErrorMessage },
    data() {
      return {
        // Dialogs
        clubDialog: false,
        deleteClubDialog: false,

        // Form
        validClub: false,
        saveLoading: false,
        deleteLoading: false,
        selectedCountry: 'Brunei',
        countries: ['Australia', 'Brunei', 'Indonesia', 'Malaysia', 'Philippines', 'Singapore'],
        reciprocalClubs: [],
        club: {},
        nameRules: [name => !!name || 'Please enter a name'],
        locationRules: [location => !!location || 'Please enter a location'],
        telephoneRules: [telephone => !!telephone || 'Please enter a telephone number'],
        faxRules: [fax => !!fax || 'Please enter a fax number'],
        clubErrors: [],
        pagination: {
          current_page: 0,
          per: 0,
          total_count: 0,
          total_page: 0
        }
      }
    },
    created() {
      this.fetchReciprocalClubs()
    },
    methods: {
      // Toggle dialog methods
      toggleClubDialog(club = null) {
        this.clubErrors = []
        this.resetForm()
        this.clubDialog = !this.clubDialog
        if (club) this.getReciprocalClub(club.id)
      },
      toggleDeleteClubDialog(club = null) {
        this.deleteClubDialog = !this.deleteClubDialog
        if (club) this.club = club
        else this.club = {}
      },

      // API methods
      async fetchReciprocalClubs(page = null) {
        await apiService.get(`api/v1/reciprocal_clubs?country=${this.selectedCountry}&page=${page}`).then(res => {
          if (res.status == 200) {
            this.reciprocalClubs = res.data.data
            if (!page) this.pagination = res.data.pagination
          }
        }).catch(() => notificationService.error('Something went wrong. Please refresh.'))
      },
      async getReciprocalClub(clubId) {
        await apiService.get(`api/v1/reciprocal_clubs/${clubId}`).then(res => {
          if (res.status == 200) this.club = res.data.data
        }).catch(() => notificationService.error('Something went wrong. Please refresh.'))
      },
      async saveReciprocalClub() {
        this.saveLoading = true
        this.clubErrors = []
        if (this.club.id) {
          await apiService.update(`api/v1/reciprocal_clubs/${this.club.id}`, this.club).then(res => {
            if (res.status == 200) this.cleanUpSuccess(res.data.message)
          }).catch(err => this.cleanUpFailure(err))
        } else {
          await apiService.create('api/v1/reciprocal_clubs', this.club).then(res => {
            if (res.status == 200) this.cleanUpSuccess(res.data.message)
          }).catch(err => this.cleanUpFailure(err))
        }
      },
      async deleteReciprocalClub(clubId) {
        this.deleteLoading = true
        await apiService.delete(`api/v1/reciprocal_clubs/${clubId}`).then(res => {
          if (res.status == 200) this.cleanUpSuccess(res.data.message)
        }).catch(err => this.cleanUpFailure(err))
      },

      // Clean up methods
      resetForm() {
        this.$refs.ClubForm.reset()
        this.club = {}
      },
      cleanUpSuccess(content) {
        notificationService.success(content)
        this.fetchReciprocalClubs()
        this.resetLoaders()
        this.clubDialog = false
        this.deleteClubDialog = false
      },
      cleanUpFailure(err) {
        this.resetLoaders()
        this.clubErrors = err.response.data.errors
        notificationService.error(err.response.data.message || 'Something went wrong. Please refresh.')
      },
      resetLoaders() {
        this.saveLoading = false
        this.deleteLoading = false
      }
    }
  }
</script>

<style lang="scss">
  @import '@/scss/style.scss';

  #clubs-header {
    h1 {
      font-size: 2em;

      @media #{map-get($display-breakpoints, 'xs-only')} {
        font-size: 1.5em;
      }
    }
  }
</style>
