<template>
  <v-app>
    <v-main class="pa-0">
      <SideDrawer v-if="adminPagesCheck()"/>
      <Navbar v-if="!adminPagesCheck()"/>
      <Banner v-if="showBanner && !adminPagesCheck()"/>
      <transition name="slide" mode="out-in" v-if="!adminPagesCheck()">
        <router-view></router-view>
      </transition>
      <Footer v-if="!adminPagesCheck()"/>
      <Snackbar/>
    </v-main>
  </v-app>
</template>

<script>
  import Navbar from '@/components/Navbar'
  import Banner from '@/components/Banner'
  import Footer from '@/components/Footer'
  import SideDrawer from '@/components/SideDrawer'
  import Snackbar from '@/components/Snackbar'

  export default {
    name: 'App',
    components: { Navbar, Banner, Footer, SideDrawer, Snackbar },
    data() {
      return {
        showBanner: true
      }
    },
    watch:{
      $route (to) {
        this.setBanner(to)
        this.adminPagesCheck()
      }
    },
    created() {
      this.setBanner(this.$route)
      this.adminPagesCheck()
    },
    methods: {
      setBanner(path) {
        if (path.name == 'PostDetails') this.showBanner = false
        else this.showBanner = true
      },
      adminPagesCheck() {
        return ['AdminDashboard', 'AdminCommitteeMembers', 'AdminReciprocalClubs',
                'AdminCoursesDetails', 'AdminFacilities', 'AdminMemberships',
                'AdminBanners', 'AdminPosts', 'AdminPostNew', 'AdminPostEdit'].includes(this.$route.name)
      }
    }
  }
</script>

<style lang="scss">
  .slide-enter-active, .slide-leave-active {
    transition: opacity .2s, transform .2s;
  }

  .slide-enter, .slide-leave-to {
    opacity: 0;
    transform: translateX(-100%);
  }
</style>
