import store from '@/store'

export default {
  success(content) {
    store.commit('showNotification', { content, color: 'success' })
  },
  error(content) {
    store.commit('showNotification', { content, color: 'error' })
  },
  warning(content) {
    store.commit('showNotification', { content, color: 'warning' })
  },
  info(content) {
    store.commit('showNotification', { content, color: 'info' })
  }
}
