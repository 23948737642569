<template>
  <div class="mt-8">
    <section id="introduction-section">
      <v-row class="my-5">
        <v-col cols="12" sm="6" class="bg-primary pa-0">
          <div class="white--text pa-15" :class="{ 'px-10 py-5': $vuetify.breakpoint.xsOnly }">
            <h1>Where we are</h1>
            <p class="ma-0">Pantai Mentiri Golf Club is located 15.5km along Kota Batu Road and enjoys a scenic view of Brunei Bay. It is easily accessible from Bandar Seri Begawan, a mere 15 minutes drive, from Gadong Commercial Centre, about 20 minutes drive, and from Brunei International Airport, a good 10 minutes drive.</p>
            <router-link to="/contact-us#working-hours">
              <v-btn text plain class="white--text mt-2 pl-0" :ripple="false">Our operating hours<v-icon right>mdi-chevron-right</v-icon></v-btn>
            </router-link>
          </div>
        </v-col>
        <v-col cols="12" sm="6" class="introduction-image"></v-col>
      </v-row>
    </section>

    <section id="course-details-section">
      <v-row class="my-5">
        <v-col cols="12" sm="6" class="d-flex align-center justify-center">
          <em class="course-details">
            <h1>72 <span class="gray--text">par</span></h1>
            <h1>6105 <span class="gray--text">metres</span></h1>
            <h1>18 <span class="gray--text">holes</span></h1>
          </em>
        </v-col>
        <v-col cols="12" sm="6" class="pa-0">
          <div class="gray--text pa-15" :class="{ 'px-10 py-5': $vuetify.breakpoint.xsOnly }">
            <p>For seasoned golfers and professionals it is a straightforward 6105 metres par 72 course. For golfers with a 10 handicap and above the course will provide enough challenge to make a net par score difficult.</p>
            <p class="ma-0">The challenge of Pantai Mentiri Golf course is centred on the golfer’s ability to avoid the many water hazards which form the main feature of the course. The greens, with well manicured and contoured aprons, are amongst the best in South East Asia.</p>
            <router-link to="/courses/hole-details">
              <v-btn text plain class="secondary--text mt-2 pl-0" :ripple="false">Check our courses<v-icon right>mdi-chevron-right</v-icon></v-btn>
            </router-link>
          </div>
        </v-col>
      </v-row>
    </section>

    <section id="club-introduction-section" class="pa-15 white--text bg-primary my-5" :class="{ 'px-10': $vuetify.breakpoint.xsOnly }">
      <h1 class="header-size" :class="{ 'px-15': $vuetify.breakpoint.smAndUp }">Pantai Mentiri Golf Club</h1>
      <h2 :class="{ 'px-15': $vuetify.breakpoint.smAndUp }">is the venue of many organised tournaments including the 20th SEA Games in 1999.</h2>
    </section>

    <section id="flight-details-section" class="text-center my-5">
      <h1 class="header-size my-5">1 flight <v-icon large color="black">mdi-ray-start-arrow</v-icon> 4 players</h1>
      <v-row class="my-5">
        <v-col cols="12" sm="4" offset-sm="2">
          <h2 class="black--text"><v-icon class="pr-3">mdi-golf</v-icon>Morning</h2>
          <h1 class="black--text header-size">20 - 30 flights</h1>
        </v-col>
        <v-col cols="12" sm="4">
          <h2><v-icon class="pr-3">mdi-golf</v-icon>Afternoon</h2>
          <h1 class="header-size">20 - 30 flights</h1>
        </v-col>
      </v-row>
    </section>

    <section v-if="membershipPlans.length" id="memberships-section" class="text-center">
      <h1 class="header-size my-5">Membership Plans</h1>
      <v-row class="mb-10" :class="{ 'px-10': $vuetify.breakpoint.smAndUp, 'px-5': $vuetify.breakpoint.xsOnly }">
        <v-col cols="12" sm="6" md="3" v-for="plan in membershipPlans" :key="plan.id">
          <v-card outlined class="pa-5 membership-card rounded-b-0">
            <h1>{{plan.title}} ({{plan.code}})</h1>
            <v-divider></v-divider>
            <h4 class="my-5" :class="{ 'primary--text font-weight-bold': isPromo(plan.showPromo, plan.monthly_fee_promo) }">
              BND
              <span class="header-size">
                {{isPromo(plan.showPromo, plan.monthly_fee_promo) ? plan.monthly_fee_promo : plan.monthly_fee | removeDecimals}}
              </span>/month
            </h4>
            <div class="text-center black--text">
              <h3
                v-if="isFeeAvailable(plan.entrance_fee) || isFeeAvailable(plan.entrance_fee_promo)"
                :class="{ 'primary--text font-weight-bold': isPromo(plan.showPromo, plan.entrance_fee_promo) }"
              >
                BND${{isPromo(plan.showPromo, plan.entrance_fee_promo) ? plan.entrance_fee_promo : plan.entrance_fee | removeDecimals}} entrance fee
              </h3>
              <h3
                v-if="isFeeAvailable(plan.yearly_fee) || isFeeAvailable(plan.yearly_fee_promo)"
                class="mt-5"
                :class="{ 'primary--text font-weight-bold': isPromo(plan.showPromo, plan.yearly_fee_promo) }"
              >
                BND${{isPromo(plan.showPromo, plan.yearly_fee_promo) ? plan.yearly_fee_promo : plan.yearly_fee | removeDecimals}} yearly fee
              </h3>
              <p class="mt-5 mb-5">{{plan.description}}</p>
            </div>
          </v-card>
          <v-btn
            v-if="isFeeAvailable(plan.monthly_fee_promo) || isFeeAvailable(plan.entrance_fee_promo)  || isFeeAvailable(plan.yearly_fee_promo)"
            block depressed color="primary" class="rounded-t-0 promo-btn" style="bottom: 36px;"
            @click="plan.showPromo = !plan.showPromo"
          >
            {{plan.showPromo ? 'Hide promotion' : 'Show promotion!'}}
          </v-btn>
        </v-col>
      </v-row>
    </section>

    <section id="hole-details-section">
      <v-row class="bg-primary white--text my-5">
        <v-col cols="12" sm="6" class="text-left pa-15 my-5" :class="{ 'px-10': $vuetify.breakpoint.xsOnly }">
          <h2>The first nine holes, the Bakau nine, being 3006 metres long from the blue tee boxes, are considered by many to be the “easier” nine. </h2>
        </v-col>
        <v-col cols="12" sm="6" class="text-right pa-15 my-5" :class="{ 'px-10': $vuetify.breakpoint.xsOnly }">
          <h2>The inward Membangan nine measures 3099 metres while the ladies` red tee boxes provide lengths of 2556 metres and 2651 metres respectively.</h2>
        </v-col>
      </v-row>
    </section>

    <section id="contact-us-section">
      <v-row class="py-10 my-5" :class="{ 'px-10': $vuetify.breakpoint.xsOnly, 'px-15': $vuetify.breakpoint.smAndUp }">
        <v-col cols="12" sm="6">
          <h1>Get in touch now!</h1>
          <h2 class="mb-0">At PMGC, we are happy to answer any questions that you might have.</h2>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex align-center justify-center">
          <router-link to="/contact-us">
            <v-btn x-large outlined depressed color="secondary" class="v-btn__secondary">Contact us<v-icon right>mdi-chevron-right</v-icon></v-btn>
          </router-link>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
  import apiService from '@/services/api.service'

  export default {
    name: 'Home',
    data() {
      return {
        membershipPlans: []
      }
    },
    filters: {
      removeDecimals(value) {
        if (value && !isNaN(value)) {
          return value.split('.')[0]
        }
      }
    },
    created() {
      this.fetchMembershipPlans()
    },
    methods: {
      async fetchMembershipPlans() {
        await apiService.get('api/v1/membership_types/list').then(res => {
          if (res.status == 200) this.membershipPlans = res.data.data.map(paln => ({ ...paln, showPromo: false }))
        }).catch(() => {})
      },
      isFeeAvailable(fee) { return ((fee != '0.0') && (fee != null)) },
      isPromo(showPromo, fee) { return showPromo && this.isFeeAvailable(fee) }
    }
  }
</script>

<style lang="scss">
  @import '@/scss/style.scss';

  #introduction-section {
    .introduction-image {
      background: url('../assets/banner1.jpg');
      background-size: cover;
      background-position: center;

      @media #{map-get($display-breakpoints, 'xs-only')} {
        min-height: 25em;
      }
    }
  }

  #course-details-section {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      h1 {
        font-size: 1.2em;
      }
    }

    .course-details {
      font-size: 3.5em;
      line-height: 100px;

      @media #{map-get($display-breakpoints, 'sm-only')} {
        font-size: 2.9em;
        line-height: 130px;
      }

      @media #{map-get($display-breakpoints, 'xs-only')} {
        line-height: 80px;
        font-size: 3em;
      }
    }
  }

  #club-introduction-section {
    h2 {
      font-size: 2em;
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
      h1 {
        font-size: 2.5em;
      }

      h2 {
        font-size: 1.5em;
      }
    }
  }

  #flight-details-section {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      h1 {
        font-size: 2em;
      }
    }
  }

  #memberships-section {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      h1 {
        font-size: 2em;
      }
    }

    @media #{map-get($display-breakpoints, 'xl-only')} {
      .promo-btn {
        font-size: 1.25em;
      }
    }

    .membership-card {
      min-height: 31em;
      position: relative;

      .theme--light.v-divider {
        border-color: $secondary !important;
        max-width: 100%;
      }

      h1 {
        font-size: 32px;
        line-height: 40px;
      }

      @media #{map-get($display-breakpoints, 'xl-only')} {
        h1 {
          font-size: 2.5em;
        }

        h3, p {
          font-size: 1.5em;
        }
      }
    }
  }
</style>
