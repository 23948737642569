<template>
  <div>
    <section id="posts-header">
      <v-row class="mb-5">
        <v-col cols="12" sm="8" class="px-0">
          <h1>Manage news posts</h1>
          <h3 class="gray--text">Draft, edit or publish new posts related to PMGC activities</h3>
        </v-col>
        <v-col cols="12" sm="4" class="px-0">
          <router-link to="/admin/news/new">
            <v-btn depressed block large color="secondary"><v-icon class="pr-5">mdi-plus</v-icon>Add post</v-btn>
          </router-link>
        </v-col>
      </v-row>
    </section>

    <section id="posts-table">
      <v-row v-if="$vuetify.breakpoint.smAndUp" class="gray--text">
        <v-col sm="5"><h1>Title</h1></v-col>
        <v-col sm="2"><h1>Date</h1></v-col>
        <v-col sm="1"><h1>Status</h1></v-col>
        <v-col sm="4" class="text-center"><h1>Actions</h1></v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card v-for="post in posts" :key="post.id" class="my-5">
        <v-row class="d-flex align-center">
          <v-col cols="12" sm="5"><h3>{{post.title}}</h3></v-col>
          <v-col cols="12" sm="2"><h3>{{post.updated_at}}</h3></v-col>
          <v-col cols="12" sm="1" align="center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" :class="{ 'success--text': post.is_published, 'danger--text': !post.is_published }">
                  {{post.is_published ? 'mdi-note-check-outline' : 'mdi-note-edit-outline'}}
                </v-icon>
              </template>
              <span>{{post.is_published ? 'Published' : 'Draft'}}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12" sm="4" class="pa-0">
            <v-row>
              <v-col cols="6"><v-btn small block outlined color="success" :to="`/admin/news/edit/${post.id}`">Edit</v-btn></v-col>
              <v-col cols="6"><v-btn small block outlined color="danger" @click="toggleDeletePostDialog(post)">Remove</v-btn></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-row v-if="posts.length == 0" >
        <div class="mt-15 mb-5 mx-auto">
          <h1>No posts to show yet!</h1>
        </div>
      </v-row>
      <div class="text-center mt-10">
        <v-pagination
          v-model="pagination.current_page"
          :length="pagination.total_page"
          :total-visible="7"
          @input="fetchPosts(pagination.current_page)">
        </v-pagination>
      </div>
    </section>

    <v-dialog v-model="deletePostDialog" max-width="500px">
      <v-card>
        <v-card-title class="black--text">
          <span><v-icon color="black">mdi-alert-circle-outline</v-icon> Remove <span class="primary--text">"{{post.title}}"</span> from current committee members list?</span>
        </v-card-title>
        <v-card-actions class="pb-5 pr-8">
          <v-spacer></v-spacer>
          <v-btn color="danger" text @click="toggleDeletePostDialog()">Cancel</v-btn>
          <v-btn depressed color="success" class="px-10"
            :loading="deleteLoading" :disabled="deleteLoading" @click="deletePost(post.id)">
            Yes, remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import apiService from '@/services/api.service'
  import notificationService from '@/services/notification.service'

  export default {
    name: 'AdminPosts',
    data() {
      return {
        deletePostDialog: false,
        deleteLoading: false,
        post: {},
        posts: [],
        pagination: {
          current_page: 0,
          per: 0,
          total_count: 0,
          total_page: 0
        }
      }
    },
    created() {
      this.fetchPosts()
    },
    methods: {
      // Toggle dialog methods
      toggleDeletePostDialog(post = null) {
        this.deletePostDialog = !this.deletePostDialog
        if (post) this.post = post
      },

      // API methods
      async fetchPosts(page = null) {
        await apiService.get(`api/v1/posts?page=${page}`).then(res => {
          if (res.status == 200) {
            this.posts = res.data.data
            if (!page) this.pagination = res.data.pagination
          }
        }).catch(() => notificationService.error('Something went wrong. Please refresh.'))
      },
      async deletePost(postId) {
        this.deleteLoading = true
        await apiService.delete(`api/v1/posts/${postId}`).then(res => {
          if (res.status == 200) {
            notificationService.error(res.data.message)
            this.fetchPosts()
            this.deleteLoading = false
            this.deletePostDialog = false
          }
        }).catch(err => notificationService.error(err.response.data.message || 'Something went wrong. Please refresh.'))
      }
    }
  }
</script>

<style lang="scss">
  @import '@/scss/style.scss';

  #posts-header {
    h1 {
      font-size: 2em;

      @media #{map-get($display-breakpoints, 'xs-only')} {
        font-size: 1.5em;
      }
    }
  }
</style>
