<template>
  <div>
    <section id="banner-image">
      <v-img :lazy-src="post.featured_image_url || require('@/assets/default-landscape.png')"
        :src="post.featured_image_url || require('@/assets/default-landscape.png')">
        <v-overlay absolute></v-overlay>
      </v-img>
    </section>

    <section id="post-content" :class="{ 'px-8': $vuetify.breakpoint.xsOnly, 'px-15': $vuetify.breakpoint.smAndUp }">
      <h1 class="text--primary">{{post.title}}<br></h1>
      <p class="gray--text">Posted on {{post.published_at}} by {{post.author}}</p>
      <h3 class="gray--text" v-html="post.content"></h3>
    </section>

    <section id="post-carousel" class="pt-15">
      <v-carousel hide-delimiters height="100%">
        <v-carousel-item v-for="gallery in post.galleries" :key="gallery.id">
          <v-img :aspect-ratio="16/9" width="auto" :src="gallery.url || require('@/assets/default-landscape.png')">
            <template v-slot:placeholder>
              <v-container fill-height class="d-flex align-center justify-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-container>
            </template>
          </v-img>
        ></v-carousel-item>
      </v-carousel>
    </section>

    <div class="d-flex align-center justify-center py-15">
      <router-link to="/news">
        <v-btn x-large outlined color="white" class="v-btn__secondary secondary--text">Back to list</v-btn>
      </router-link>
    </div>
  </div>
</template>

<script>
  import apiService from '@/services/api.service'

  export default {
    name: 'PostDetails',
    data() {
      return {
        post: {}
      }
    },
    created() {
      this.getPost()
    },
    methods: {
      async getPost() {
        await apiService.get(`api/v1/posts/${this.$route.params.id}`).then(res => {
          if (res.status == 200) this.post = res.data.data
        }).catch(() => {})
      }
    }
  }
</script>

<style lang="scss">
  @import '@/scss/style.scss';

  #banner-image {
    .v-overlay {
      display: inline !important;
    }

    .v-overlay__scrim {
      opacity: 1 !important;
      background: rgb(255, 255, 255) !important;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 1) 100%) !important;
    }
  }

  #post-content {
    h1 {
      font-size: 2em;
      line-height: 40px;
      padding-bottom: 20px;
      text-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
    }
  }
</style>
