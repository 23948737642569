import axios from 'axios'
import encryptionService from '@/services/encryption.service'

const baseURL = process.env.VUE_APP_API_URL

const axiosIns = axios.create({
  baseURL,
  timeout: 5000,
  headers: { 'Access-Control-Allow-Origin': '*' },
})

axiosIns.interceptors.request.use(config => {
  const encryptedAuthHeaders = localStorage.getItem('authHeaders')

  if (encryptedAuthHeaders) {
    const authHeaders = JSON.parse(encryptionService.decrypt(encryptedAuthHeaders))
    config.headers[config.method] = {
      'access-token': authHeaders['access-token'],
      'client': authHeaders['client'],
      'uid': authHeaders['uid'],
      'expiry': authHeaders['expiry'],
      'token-type': authHeaders['token-type']
    }
  }
  return config
}, error => {
  return Promise.reject(error)
})

axiosIns.interceptors.response.use(response => {
  if (response.headers['access-token']) {
    const authHeaders = {
      'access-token': response.headers['access-token'],
      'client': response.headers['client'],
      'uid': response.headers['uid'],
      'expiry': response.headers['expiry'],
      'token-type': response.headers['token-type']
    }
    const encryptedAuthHeaders = encryptionService.encrypt(JSON.stringify(authHeaders))
    localStorage.setItem('authHeaders', encryptedAuthHeaders)
  }
  return response
}, error => {
  return Promise.reject(error)
})

export default {
  get(endpoint, params = {}) {
    return axiosIns.get(`${baseURL}/${endpoint}`, { params })
  },
  create(endpoint, params = {}) {
    return axiosIns.post(`${baseURL}/${endpoint}`, params)
  },
  update(endpoint, params = {}) {
    return axiosIns.put(`${baseURL}/${endpoint}`, params)
  },
  patch(endpoint, params = {}) {
    return axiosIns.patch(`${baseURL}/${endpoint}`, params)
  },
  delete(endpoint, params = {}) {
    return axiosIns.delete(`${baseURL}/${endpoint}`, { params })
  }
}
